import * as types from './mutation-types'

export default {
  [types.SET_ALL_CHALLENGES_DATA] (state, data) {
    state.allChallenges = data
  },
  [types.SET_SINGLE_CHALLENGE_DATA] (state, data) {
    state.singleChallenge = data
  },
  [types.SET_SINGLE_CHALLENGE_PRIZES_INFO] (state, data) {
    state.singleChallengePrizesInfo = data
  },
  [types.SET_SINGLE_CHALLENGE_PRIZES_DATA] (state, data) {
    state.singleChallengePrizes = data
  },
  [types.SET_SINGLE_CHALLENGE_RESULTS_INFO_DATA] (state, data) {
    state.singleChallengeResultsInfo = data
  },
  [types.SET_SINGLE_CHALLENGE_RESULTS_DATA] (state, data) {
    state.singleChallengeResults = data
  },
  [types.SET_SINGLE_CHALLENGE_TEAM_RESULTS_DATA] (state, data) {
    state.singleChallengeTeamResults = data
  },
  [types.SET_CHALLENGES_SETTINGS_DATA] (state, data) {
    state.challengesSettings = data
  },
  [types.SET_LOADING] (state, trueOrFalse) {
    state.loading = trueOrFalse
  },
  [types.SET_TASKS_LOADING] (state, trueOrFalse) {
    state.tasksLoading = trueOrFalse
  },
  [types.SET_TASKS_DATA] (state, data) {
    state.dayTasks = data
  },
  [types.SET_RESULTS_LOADING] (state, trueOrFalse) {
    state.loadingResults = trueOrFalse
  },
  [types.SET_TEAM_RESULTS_LOADING] (state, trueOrFalse) {
    state.loadingTeamResults = trueOrFalse
  },
  [types.SET_CHALLENGE_COMPLETED] (state, data) {
    state.challengeCompleted = data
  },
  [types.SET_QUIZ_COMPLETED] (state, data) {
    state.quizCompleted = data
  },
  [types.SET_VIDEO_COMPLETED] (state, data) {
    state.videoCompleted = data
  },
  [types.SET_READ_COMPLETED] (state, data) {
    state.readCompleted = data
  },
  [types.SET_INTEGRATION_COMPLETED] (state, data) {
    state.integrationCompleted = data
  },
  [types.SET_CHALLENGE_COMPLETED_IMAGES] (state, data) {
    state.challengeCompletedImages = data
  },
  [types.SET_CHALLENGES_PAYMENT_DATA] (state, data) {
    state.challengesPayment = data
  }
}
