const Nope = () => import(/* webpackChunkName: "Errors" */ '../components/Nope')

export default [
  {
    path: '*',
    component: Nope
  },
  {
    path: '/404',
    component: Nope
  }
]
