export const SET_ALL_CHALLENGES_DATA = 'SET_ALL_CHALLENGES_DATA'
export const SET_SINGLE_CHALLENGE_DATA = 'SET_SINGLE_CHALLENGE_DATA'
export const SET_PRIZES_CATEGORIES_DATA = 'SET_PRIZES_CATEGORIES_DATA'
export const SET_PRIZES_PARTNERS_DATA = 'SET_PRIZES_PARTNERS_DATA'
export const SET_PRIZES_ITEMS_DATA = 'SET_PRIZES_ITEMS_DATA'
export const SET_LOTTERY_WINNERS_DATA = 'SET_LOTTERY_WINNERS_DATA'
export const SET_ALL_LOTTERIES_DATA = 'SET_ALL_LOTTERIES_DATA'
export const SET_SINGLE_LOTTERY_DATA = 'SET_SINGLE_LOTTERY_DATA'
export const SET_LOADING = 'SET_LOADING'
export const SET_SINGLE_CHALLENGE_DAYS = 'SET_SINGLE_CHALLENGE_DAYS'
export const SET_SINGLE_CHALLENGE_DAY = 'SET_SINGLE_CHALLENGE_DAY'
export const SET_SINGLE_CHALLENGE_TASKS = 'SET_SINGLE_CHALLENGE_TASKS'
export const SET_SINGLE_CHALLENGE_TASK = 'SET_SINGLE_CHALLENGE_TASK'
export const SET_DAY_LOADING = 'SET_DAY_LOADING'
export const SET_TASKS_LOADING = 'SET_TASKS_LOADING'
