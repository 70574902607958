import * as api from '../api/index'
import * as types from './mutation-types'
import store from '@/store'
import router from '@/router'

export const getBadges = ({ commit }, { payload, context }) => {
  context.isLoading = true
  api.getBadges(payload).then((response) => {
    commit(types.SET_BADGES_DATA, response.data.data)
    context.isLoading = false
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const checkUnlockedBadges = ({ commit }) => {
  api.checkUnlockedBadges().then((response) => {
    if (!_.isEmpty(response.data.data)) {
      commit(types.SET_UNLOCKED_BADGES, response.data.data)
      store.commit('modals/SET_SHOW_UNLOCKED_BADGES', true)
    }
  }).catch((error) => {
    // context.errors = error.response.data.errors
  })
}
