import * as types from './mutation-types'

export default {
  [types.SET_BADGES_DATA] (state, data) {
    state.badges = data

    // Filter only user earned badges
    let userBadges = _.filter(data, { user_earned: true })
    state.userBadges = userBadges
  },
  [types.SET_UNLOCKED_BADGES] (state, data) {
    state.unlockedBadges = data
  }
}
