import * as api from '../api'
import * as types from './mutation-types'

export const getAllProvidersData = ({ commit }, { context }) => {
  api.getAllProviders().then((response) => {
    commit(types.SET_PROVIDERS_DATA, response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getConnectedProvidersData = ({ commit }, { context }) => {
  api.getConnectedProviders().then((response) => {
    commit(types.SET_CURRENT_PROVIDERS, response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const setProvider = ({ commit, dispatch }, { payload, context }) => {
  return api.postProvider(payload).then((response) => {
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    } else {
      dispatch('getConnectedProvidersData', {context: context}).then((result) => {
        context.deviceConnected = true
      })
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const setDefaultProvider = ({ commit, dispatch }, { payload, context }) => {
  return api.setDefaultProvider(payload).then((response) => {
    dispatch('getConnectedProvidersData', {context: context})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deleteProvider = ({ commit }, { payload, context }) => {
  return api.deleteProvider(payload).then((response) => {
    // Deleted
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const suggestProvider = ({ commit }, { context, payload }) => {
  api.suggestProvider(payload).then((response) => {
    //
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}
