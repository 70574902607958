export const SET_ALL_CHALLENGES_DATA = 'SET_ALL_CHALLENGES_DATA'
export const SET_SINGLE_CHALLENGE_DATA = 'SET_SINGLE_CHALLENGE_DATA'
export const SET_SINGLE_CHALLENGE_RESULTS_DATA = 'SET_SINGLE_CHALLENGE_RESULTS_DATA'
export const SET_SINGLE_CHALLENGE_PRIZES_INFO = 'SET_SINGLE_CHALLENGE_PRIZES_INFO'
export const SET_SINGLE_CHALLENGE_PRIZES_DATA = 'SET_SINGLE_CHALLENGE_PRIZES_DATA'
export const SET_CHALLENGES_SETTINGS_DATA = 'SET_CHALLENGES_SETTINGS_DATA'
export const SET_LOADING = 'SET_LOADING'
export const SET_RESULTS_LOADING = 'SET_RESULTS_LOADING'
export const SET_CHALLENGE_COMPLETED = 'SET_CHALLENGE_COMPLETED'
export const SET_CHALLENGE_COMPLETED_IMAGES = 'SET_CHALLENGE_COMPLETED_IMAGES'
export const SET_COMPANY_TEAMS_DATA = 'SET_COMPANY_TEAMS_DATA'
export const SET_COMPANY_TEAM_EMPLOYEES_DATA = 'SET_COMPANY_TEAM_EMPLOYEES_DATA'
export const SET_COMPANY_BRANDING_DATA = 'SET_COMPANY_BRANDING_DATA'
