export default {
  loading: true,
  loadingResults: true,
  allChallenges: {},
  singleChallenge: {},
  singleChallengeResults: {},
  singleChallengePrizesInfo: {},
  singleChallengePrizes: [],
  challengesSettings: {},
  challengeCompleted: {},
  challengeCompletedImages: {},
  companyTeams: [],
  companyTeamEmployees: {},
  companyBranding: {}
}
