import * as api from '../api/index'
import * as types from './mutation-types'
import router from '@/router'
import authState from '@/app/auth/store/state'
import store from '@/store'

export const getAllChallengesData = ({ commit }, { context }) => {
  commit(types.SET_LOADING, true)
  api.getAllChallengesData().then((response) => {
    commit(types.SET_ALL_CHALLENGES_DATA, response.data)
    commit(types.SET_LOADING, false)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeData = ({ commit }, { context, payload }) => {
  commit(types.SET_LOADING, true)
  api.getSingleChallengeData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_DATA, response.data)
    commit(types.SET_LOADING, false)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const createNewChallenge = ({ dispatch }, { context, payload }) => {
  api.createNewChallenge(payload).then((response) => {
    dispatch('getAllChallengesData', { context: this })
    let challengeId = response.data.data.id
    router.push({name: 'mini.company.challenges.single.edit', params: { challengeId: challengeId}})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deleteChallenge = ({ dispatch }, { context, payload }) => {
  api.deleteChallenge(payload).then((response) => {
    router.push({name: 'dashboard.company.challenges'})
    dispatch('getAllChallengesData', { context: this })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const applyToChallenge = ({ dispatch }, { context, payload }) => {
  api.applyToChallenge(payload).then((response) => {
    let challengeId = payload.challenge_id
    dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    context.success = true
    router.push({
      name: 'mini.challenges.single.home',
      params: { challengeId: challengeId }
    })
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const unapplyFromChallenge = ({ dispatch }, { context, payload }) => {
  api.unapplyFromChallenge(payload).then((response) => {
    dispatch('getAllChallengesData', { context: this })
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeResultsData = ({ commit }, { context, payload }) => {
  commit(types.SET_RESULTS_LOADING, true)
  api.getSingleChallengeResultsData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_RESULTS_DATA, response.data)
    commit(types.SET_RESULTS_LOADING, false)
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengePrizesInfo = ({ commit }, { context, payload }) => {
  api.getSingleChallengePrizesInfo(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_PRIZES_INFO, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengePrizesData = ({ commit }, { context, payload }) => {
  api.getSingleChallengePrizesData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_PRIZES_DATA, response.data)
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallenge = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallenge(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle

    if (context.social_share) {
      completedData.social_share = context.social_share
    }

    commit(types.SET_CHALLENGE_COMPLETED, completedData)

    context.social_share = false
    let challengeId = payload.challenge_id
    dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const changeCompletedImage = ({ dispatch, commit }, { context, payload }) => {
  api.changeCompletedImage(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle
    completedData.day = context.completeDay

    if (context.social_share) {
      completedData.social_share = context.social_share
    }

    commit(types.SET_CHALLENGE_COMPLETED, completedData)

    context.social_share = false
    let challengeId = payload.challenge_id
    dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const clearChallengeCompleted = ({ commit }, { context }) => {
  commit(types.SET_CHALLENGE_COMPLETED, {})
}

export const getChallengeCompletedImages = ({ commit }, { context, payload }) => {
  api.getChallengeCompletedImages(payload).then((response) => {
    commit(types.SET_CHALLENGE_COMPLETED_IMAGES, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getChallengesSettingsData = ({ commit }, { context }) => {
  api.getChallengesSettingsData().then((response) => {
    commit(types.SET_CHALLENGES_SETTINGS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const changeChallengeSettings = ({ dispatch }, { context, payload }) => {
  api.changeChallengeSettings(payload).then((response) => {
    dispatch('getChallengesSettingsData', { context: this })
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getCompanyTeams = ({ commit }, { context }) => {
  api.getCompanyTeams().then((response) => {
    commit(types.SET_COMPANY_TEAMS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const createCompanyTeam = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.success = false
  api.createCompanyTeam(payload).then((response) => {
    context.newTeam = {
      title: '',
      description: '',
      max_limit: null
    }
    dispatch('getCompanyTeams', { context: this })
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateCompanyTeam = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.success = false
  api.updateCompanyTeam(payload).then((response) => {
    context.selectedTeam = {}
    dispatch('getCompanyTeams', { context: this })
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateCompanyTeamsOrder = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.success = false
  api.updateCompanyTeamsOrder(payload).then((response) => {
    dispatch('getCompanyTeams', { context: this })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getCompanyTeamEmployees = ({ commit }, { context, payload }) => {
  api.getCompanyTeamEmployees(payload).then((response) => {
    commit(types.SET_COMPANY_TEAM_EMPLOYEES_DATA, response.data)
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateCompanyTeamEmployees = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.success = false
  api.updateCompanyTeamEmployees(payload).then((response) => {
    dispatch('getCompanyTeams', { context: this })
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const createCompany = ({ commit }, { context, payload }) => {
  context.isLoading = true
  context.errors = []
  api.createCompany(payload).then((response) => {
    context.success = response.status
    // context.isLoading = false
    context.errors = []

    let slug = response.data.data.slug
    store.dispatch('auth/fetchUser', { context: context }).then(() => {
      if (slug) {
        store.dispatch('auth/fetchCompany', { payload: slug })
      }
      router.push({name: 'mini.company.home'})
    })
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const updateCompany = ({ commit }, { payload, context }) => {
  api.updateCompany(payload).then((response) => {
    // commit(types.SET_COMPANY, response.data.data)
    authState.user.data.currentCompany = response.data.data
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getCompanyBranding = ({ commit }, { context }) => {
  context.isLoading = true
  api.getCompanyBranding().then((response) => {
    commit(types.SET_COMPANY_BRANDING_DATA, response.data)
    context.brandingDataOriginal = _.cloneDeep(response.data)
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const updateCompanyBranding = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.success = false
  context.isLoading = true
  api.updateCompanyBranding(payload).then((response) => {

    let oldVal = _.cloneDeep(context.brandingDataOriginal)
    let newVal = _.cloneDeep(payload)

    context.brandingDataOriginal = _.cloneDeep(payload)
    context.success = true
    context.isLoading = false

    if (!_.isEmpty(oldVal.login) && !_.isEmpty(oldVal.dashboard)) {
      // Show unlocked feature modal if logo has been uploaded for branding
      if (!oldVal.login.image && !oldVal.dashboard.image) {
        if (newVal.login.image || newVal.dashboard.image) {
          store.commit('auth/SET_JUST_UNLOCKED_FEATURE', 'challenges')
          store.commit('auth/SET_SHOW_UNLOCKED_FEATURE', true)
        }
      }
    }

    // dispatch('getCompanyBranding', { context: context })
    store.dispatch('miniAdminPages/getDashboardBranding', { context: context })
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}
