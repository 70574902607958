const ForgotStep1 = () => import(/* webpackChunkName: "AuthSteps" */ '../components/step1-forgot')
const ForgotStep1Email = () => import(/* webpackChunkName: "AuthSteps" */ '../components/step1-forgot-email')
const ForgotStep2 = () => import(/* webpackChunkName: "AuthSteps" */ '../components/step2-forgot')

export default [
  {
    path: 'step/1',
    name: 'forgot.step1',
    component: ForgotStep1,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/1/confirm',
    name: 'forgot.step1.email',
    component: ForgotStep1Email,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/2',
    name: 'forgot.step2',
    component: ForgotStep2,
    meta: { guest: true, needsAuth: false }
  }
]
