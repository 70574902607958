export default {
  authorized: true,
  uid: 'b2ab83ee-4e81-4e04-a083-a3fee1d0827a',
  user: {
    authenticated: false,
    data: {
      defaultCompany: {},
      currentCompany: {}
    }
  },
  userForm: {},
  defaultCard: {},
  companyLogin: {},
  isCompanyLoginLoading: true,
  companyTeams: [],
  companyTeamEmployees: {},
  companyTeamResults: {},
  isCompanySubdomain: false,
  userNotifications: [],
  notificationsSettings: [],
  dailyTasks: {
    data: [],
    meta: {}
  },
  companyLimits: {
    employees_limit: null,
    mc_limit: null,
    ch_limit: null,
    end_date: null,
    free_trial: false,
    allow_public: false,
    employees_added: null,
    mc_created: null,
    ch_created: null,
    offers_created: null,
    badges_created: null
  },
  showUnlockedFeature: false,
  justUnlockedFeature: ''
}
