const Home = () => import(/* webpackChunkName: "Home" */ '../components/Home')
const Login = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/Login')
const PricingPlan = () => import(/* webpackChunkName: "Home" */ '../components/PricingPlan')

export default [
  {
    path: '/',
    name: 'home',
    component: Login,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/plans',
    name: 'plans',
    component: PricingPlan,
    meta: { needsAuth: true }
  }
]
