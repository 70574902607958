import * as storage from '@/util/storage'
import * as types from './mutation-types'
import router from '@/router'

export default {
  [types.SET_AUTHENTICATED] (state, trueOrFalse) {
    state.user.authenticated = trueOrFalse
  },
  [types.SET_TOKEN] (state, token) {
    if (_.isNil(token)) {
      storage.remove('authToken')
      return
    }

    storage.set('authToken', token)
  },
  [types.SET_USER] (state, user) {
    state.user = user
  },
  [types.SET_USER_DATA] (state, data) {
    state.user.data = data
  },
  [types.SET_BASIC_USER_DATA] (state, data) {
    state.user.data.first_name = data.first_name
    state.user.data.last_name = data.last_name
    state.user.data.email_notifications = data.email_notifications
    state.user.data.image = data.image
  },
  [types.SET_USER_FORM_DATA] (state, data) {
    state.userForm = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: {
        code: data.phone.code,
        number: data.phone.number
      },
      description: data.description,
      password: '',
      password_repeat: '',
      password_new: '',
      email_notifications: data.email_notifications,
      image: data.image,
      social_only: data.social_only
    }
  },
  [types.SET_USER_DEFAULT_CARD] (state, data) {
    state.defaultCard = data
  },
  [types.SET_USER_ROLE] (state, data) {
    state.user.data.role = data
  },
  [types.SET_USER_COMPANY_POINTS] (state, data) {
    state.user.data.company_transaction_points = data
  },
  [types.SET_USER_DEFAULT_COMPANY] (state, data) {
    state.user.data.defaultCompany = data
  },
  [types.SET_COMPANY] (state, data) {
    // Check if company limits have changed to trigger unlocked feature modal
    let oldVal = state.companyLimits
    state.user.data.currentCompany = data

    if (!_.isUndefined(data.limits) && !_.isEmpty(data.limits)) {
      let newVal = data.limits
      state.companyLimits = newVal

      // Show unlocked feature modal if challenge is created
      if (parseInt(oldVal.ch_created) === 0 && parseInt(newVal.ch_created) > 0) {
        state.justUnlockedFeature = 'employees'
        state.showUnlockedFeature = true
      }

      // Show unlocked feature modal if employees added
      if (parseInt(oldVal.employees_added) === 0 && parseInt(newVal.employees_added) > 0) {
        state.justUnlockedFeature = 'marketplace'
        state.showUnlockedFeature = true
      }

      // Show unlocked feature modal if offer was added
      if (parseInt(oldVal.offers_created) === 0 && parseInt(newVal.offers_created) > 0) {
        state.justUnlockedFeature = 'badges'
        state.showUnlockedFeature = true
      }

      // Show unlocked feature modal if employees added
      if (parseInt(oldVal.badges_created) === 0 && parseInt(newVal.badges_created) > 0) {
        // state.justUnlockedFeature = 'scores'
        // state.showUnlockedFeature = tru
        name: 'dashboard.company.home'
        router.replace({name: 'dashboard.company.home'})
      }
    }
  },
  [types.SET_SHOW_UNLOCKED_FEATURE] (state, data) {
    state.showUnlockedFeature = data
  },
  [types.SET_JUST_UNLOCKED_FEATURE] (state, data) {
    state.justUnlockedFeature = data
  },
  [types.SET_COMPANY_LOGIN] (state, data) {
    state.companyLogin = data
  },
  [types.SET_IS_COMPANY_LOGIN_LOADING] (state, data) {
    state.isCompanyLoginLoading = data
  },
  [types.SET_IS_COMPANY_SUBDOMAIN] (state, slug) {
    if (slug === '' || slug === 'my') {
      state.isCompanySubdomain = false
    } else {
      state.isCompanySubdomain = true
    }
  },
  [types.SET_COMPANY_TEAMS_DATA] (state, data) {
    state.companyTeams = data
  },
  [types.SET_COMPANY_TEAM_EMPLOYEES_DATA] (state, data) {
    state.companyTeamEmployees = data
  },
  [types.SET_COMPANY_TEAM_RESULTS_DATA] (state, data) {
    state.companyTeamResults = data
  },
  [types.SET_USER_NOTIFICATIONS_DATA] (state, data) {
    state.userNotifications = data
  },
  [types.SET_USER_NOTIFICATIONS_AS_READ] (state) {
    if (!_.isUndefined(state.user.data.notifications_unread)) {
      state.user.data.notifications_unread = 0
    }
  },
  [types.SET_NOTIFICATIONS_SETTINGS_DATA] (state, data) {
    state.notificationsSettings = data
  },
  [types.SET_DAILY_TASKS] (state, data) {
    state.dailyTasks = data
  }
}
