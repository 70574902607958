const Home = () => import(/* webpackChunkName: "MiniPages" */ '../components/Home')
const ChallengesPage = () => import(/* webpackChunkName: "MiniPages" */ '../components/Challenges')

const PageSettings = () => import(/* webpackChunkName: "MiniPages" */ '../components/PageSettings')

const ForCompanies = () => import(/* webpackChunkName: "MiniPages" */ '../components/CompanyLanding')
const CompanyCreated = () => import(/* webpackChunkName: "MiniPages" */ '../components/CompanyCreated')

const PageAdminHome = () => import(/* webpackChunkName: "MiniPages" */ '../components/AdminHome')

const Single = () => import(/* webpackChunkName: "MiniPages" */ '../components/SingleChallenge')
const PageAdminEmployees = () => import(/* webpackChunkName: "MiniPages" */ '@/app/mini-admin-pages/components/Employees')
const PageAdminCompanyInfo = () => import(/* webpackChunkName: "MiniPages" */ '@/app/mini-admin-pages/components/Settings')
const PageAdminCreateCompany = () => import(/* webpackChunkName: "MiniPages" */ '@/app/mini-pages/components/CreateCompany.vue')
const PageAdminRegSuccessful = () => import(/* webpackChunkName: "MiniPages" */ '@/app/mini-pages/components/CompanyCreated')

// Children
import AdminEmployees from '@/app/mini-admin-employees/routes'
import MiniChallenges from '@/app/mini-challenges/routes'
import AdminPages from '@/app/mini-admin-pages/routes'

// import { i18n } from '@/app/boot'

export default [
  {
    path: ':challengeId(\\d+)',
    name: 'mini.challenges.single',
    component: Single,
    children: MiniChallenges,
    meta: {
      needsAuth: true
    }
  },
  {
    path: 'home',
    component: ChallengesPage,
    name: 'mini.home',
    meta: {
      needsAuth: true,
      breadcrumb: 'Challenges'
    }
  },
  {
    path: '/',
    component: ChallengesPage,
    name: 'mini.challenges',
    meta: {
      needsAuth: true,
      breadcrumb: 'All challenges'
    }
  },
  {
    path: 'all',
    component: ChallengesPage,
    name: 'mini.challenges',
    meta: {
      needsAuth: true,
      breadcrumb: 'All quests'
    }
  },
  {
    path: 'settings',
    name: 'mini.settings',
    component: PageSettings,
    meta: {
      needsAuth: true,
      breadcrumb: 'Settings'
    }
  },
  {
    path: 'for-companies',
    component: ForCompanies,
    name: 'mini.for_companies'
  },
  {
    path: 'company-created',
    component: CompanyCreated,
    name: 'mini.company_created'
  },
  {
    path: 'company',
    name: 'mini.company',
    component: PageAdminHome,
    meta: {
      adminAuth: true,
      needsAuth: true,
      dashboard: 'company',
      breadcrumb: 'company'
    },
    children: AdminPages
  },
  {
    path: 'company/employees',
    name: 'mini.company.employees',
    component: PageAdminEmployees,
    meta: {
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'employees'
    },
    children: AdminEmployees
  },
  {
    path: 'company/company-info',
    name: 'mini.company.company_info',
    component: PageAdminCompanyInfo,
    meta: {
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'company_info'
    }
  },
  {
    path: 'company/create',
    name: 'dashboard.company.create_company',
    component: PageAdminCreateCompany,
    meta: {
      needsAuth: true,
      breadcrumb: 'create_company'
    }
  },
  {
    path: 'company/success',
    name: 'dashboard.company.reg_successful',
    component: PageAdminRegSuccessful,
    meta: {
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'reg_successful'
    }
  }
]
