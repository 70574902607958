import axios from 'axios'

export const socialLogin = (payload) => {
  let provider = payload.provider
  let payload2 = {
    slug: payload.slug,
    company_secret: payload.company_secret
  }
  if (!_.isUndefined(payload.lang)) {
    payload2.lang = payload.lang
  }
  return axios.post(`${process.env.API_URL}/v1/login/${provider}`, payload2)
}

export const socialRegister = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/register/social`, payload)
}

export const registerEmail = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/register/email`, payload)
}

export const registerAccount = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/register/account`, payload)
}

export const getUserByCode = (code) => {
  return axios.get(`${process.env.API_URL}/v1/register/${code}`)
}

export const oAuthLogin = (payload) => {
  const passport = {
    client_id: 2,
    client_secret: 'qXbNllM2QbeNIjQSPuIvKubXv7BqfcimV1iM5QLi',
    grant_type: 'password'
  }

  payload = Object.assign(passport, payload)

  return axios.post(`${process.env.API_URL}/v1/login`, payload)
}

export const logout = () => {
  return axios.post(`${process.env.API_URL}/v1/logout`)
}

export const sendForgotEmail = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/forgot`, payload)
}

export const getForgotDataByCode = (code) => {
  return axios.get(`${process.env.API_URL}/v1/forgot/${code}`)
}

export const changePassword = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/forgot/change-password`, payload)
}

export const fetchUser = (slug) => {
  return axios.get(`${process.env.API_URL}/v1/me/${slug}`)
}

export const updateProfile = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/profile`, payload)
}

export const updateUserLang = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/locales`, payload)
}

export const fetchCompany = (slug) => {
  return axios.get(`${process.env.API_URL}/v1/company/account/${slug}`)
}

export const fetchCompanyOnLogin = (slug) => {
  return axios.get(`${process.env.API_URL}/v1/company/login/${slug}`)
}

export const deleteSelf = () => {
  return axios.delete(`${process.env.API_URL}/v1/profile`)
}

export const completeDeleteSelf = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/profile/${payload}`)
}

export const getUserDefaultCard = () => {
  return axios.get(`${process.env.API_URL}/v1/profile/payment`)
}

export const getCompanyTeams = () => {
  return axios.get(`${process.env.API_URL}/v1/teams`)
}

export const getCompanyTeamEmployees = (payload) => {
  let teamId = payload.teamId
  let challengeId = payload.challengeId
  return axios.get(`${process.env.API_URL}/v1/teams/employees/${teamId}/${challengeId}`)
}

export const updateCurrentTeam = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/teams`, payload)
}

export const getCompanyTeamResults = (payload) => {
  let teamId = payload.teamId
  let challengeId = payload.challengeId
  return axios.get(`${process.env.API_URL}/v1/teams/employees/${teamId}/${challengeId}/results`)
}

export const sendNotificationsToken = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/notifications/token`, payload)
}

export const getNotifications = () => {
  return axios.get(`${process.env.API_URL}/v1/notifications`)
}

export const getNotificationsSettings = () => {
  return axios.get(`${process.env.API_URL}/v1/profile/notifications`)
}

export const changeNotificationSettings = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/profile/notifications`, payload)
}

export const getDailyTasks = () => {
  return axios.get(`${process.env.API_URL}/v1/tasks`)
}

export const ping = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/ping`, payload)
}
