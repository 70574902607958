import Router from 'vue-router'
import routes from '@/app/routes'
import Vue from 'vue'

import beforeEach from './beforeEach'
import afterEach from './afterEach'
import store from '../store'

import Debug from '@/util/debug'
const debug = Debug('router:beforeEach')

import { AFTER_LOGIN_PAGE } from '@/util/constants'

Vue.use(Router)

const router = new Router({
  base: __dirname,
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

function getQueryString () {
  var key = false, res = {}, itm = null
  // get the query string without the ?
  var qs = location.search.substring(1)
  // check for the key as an argument
  if (arguments.length > 0 && arguments[0].length > 1)
    key = arguments[0]
  // make a regex pattern to grab key/value
  var pattern = /([^&=]+)=([^&]*)/g
  // loop the items in the query string, either
  // find a match to the argument, or build an object
  // with key/value pairs
  while (itm = pattern.exec(qs)) {
    if (key !== false && decodeURIComponent(itm[1]) === key) {
      return decodeURIComponent(itm[2])
    } else if (key === false) {
      res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2])
    }
  }

  return key === false ? res : null
}

// let fullUrl = window.location.href
// let url = new URL(fullUrl)
// let token = url.searchParams.get('token')
// let redirect = url.searchParams.get('redirect')

let token = getQueryString('token')
let redirect = getQueryString('redirect')

// First, let's check if token is set as GET parameter (used by native app for authorization on some pages)
if (!_.isNil(token)) {
  debug.info('is token param')

  store.dispatch('auth/setToken', token).then(() => {
    let slug = {}
    store.dispatch('auth/fetchUser', {context: slug}).then(() => {
      if (!_.isNil(slug)) {
        store.dispatch('auth/fetchCompany', {payload: slug})
      }
      // Navigate to redirect URI
      if (!_.isNil(redirect)) {
        router.replace({path: redirect})
      } else {
        router.replace({name: AFTER_LOGIN_PAGE})
      }
    }).catch((error) => {
      router.replace({name: 'login'})
    })
  }).catch((error) => {
    router.replace({name: 'login'})
  })
} else {
  debug.info('is not token param')
  store.dispatch('auth/setToken').then(() => {
    let slug = {}
    store.dispatch('auth/fetchUser', {context: slug}).then(() => {
      if (!_.isNil(slug)) {
        store.dispatch('auth/fetchCompany', {payload: slug})
      }
    }).catch(() => {
      store.dispatch('auth/clearAuth')
      router.replace({name: 'login'})
    })
  }).catch(() => store.dispatch('auth/clearAuth'))
}

export default router
