import auth from './auth/routes'
import errors from './errors/routes'
import home from './home/routes'
import modals from './modals/routes'
import statistics from './statistics/routes'
import mini from './mini/routes'

export default [
  ...auth,
  ...home,
  ...modals,
  ...statistics,
  ...mini,

  ...errors
]
