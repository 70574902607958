export const SET_STATS_DATA = 'SET_STATS_DATA'
export const SET_ALL_STATS_DATA = 'SET_ALL_STATS_DATA'
export const SET_TODAYS_MOOD = 'SET_TODAYS_MOOD'
export const SET_ALL_TRANSACTIONS_DATA = 'SET_ALL_TRANSACTIONS_DATA'
export const SET_TRANSACTIONS_BALANCE_DATA = 'SET_TRANSACTIONS_BALANCE_DATA'
export const SET_CONNECTED_PROVIDERS_ERROR = 'SET_CONNECTED_PROVIDERS_ERROR'
export const SET_STATS_ERROR = 'SET_STATS_ERROR'
export const SET_ALL_STATS_ERROR = 'SET_ALL_STATS_ERROR'
export const SET_FITLERED_STATS_ERROR = 'SET_FITLERED_STATS_ERROR'
export const SET_HEALTHSCORE_DATA = 'SET_HEALTHSCORE_DATA'
export const SET_SCORES_DATA = 'SET_SCORES_DATA'
