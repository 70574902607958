export default {
  loading: true,
  loadingResults: true,
  loadingTeamResults: true,
  allChallenges: {},
  singleChallenge: {},
  singleChallengeResultsInfo: {},
  singleChallengeResults: {},
  singleChallengeTeamResults: {},
  singleChallengePrizesInfo: {},
  singleChallengePrizes: [],
  challengesSettings: {},
  challengeCompleted: {},
  quizCompleted: {},
  videoCompleted: {},
  readCompleted: {},
  integrationCompleted: {},
  challengeCompletedImages: {},
  challengesPayment: {},
  dayTasks: [],
  tasksLoading: false
}
