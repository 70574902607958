import * as types from './mutation-types'

export default {
  [types.SET_ALL_EMPLOYEES_DATA] (state, data) {
    state.allEmployees = data
  },
  [types.SET_SINGLE_EMPLOYEE_DATA] (state, data) {
    state.singleEmployee = data
  },
  [types.SET_SINGLE_EMPLOYEE_CHARTS] (state, data) {
    state.singleEmployeeCharts = data
  },
  [types.SET_SINGLE_EMPLOYEE_RESULTS] (state, data) {
    state.singleEmployeeResults = data
  },
  [types.SET_SINGLE_EMPLOYEE_ALL_TRANSACTIONS_DATA] (state, data) {
    state.singleEmployeeAllTransactions = data
  },
  [types.SET_SINGLE_EMPLOYEE_TRANSACTIONS_BALANCE_DATA] (state, data) {
    state.singleEmployeeTransactionsBalance = data
  },
  [types.SET_LOADING] (state, trueOrFalse) {
    state.loading = trueOrFalse
  }
}
