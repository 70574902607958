export const SET_ALL_CHALLENGES_DATA = 'SET_ALL_CHALLENGES_DATA'
export const SET_SINGLE_CHALLENGE_DATA = 'SET_SINGLE_CHALLENGE_DATA'
export const SET_SINGLE_CHALLENGE_RESULTS_INFO_DATA = 'SET_SINGLE_CHALLENGE_RESULTS_INFO_DATA'
export const SET_SINGLE_CHALLENGE_RESULTS_DATA = 'SET_SINGLE_CHALLENGE_RESULTS_DATA'
export const SET_SINGLE_CHALLENGE_TEAM_RESULTS_DATA = 'SET_SINGLE_CHALLENGE_TEAM_RESULTS_DATA'
export const SET_SINGLE_CHALLENGE_PRIZES_INFO = 'SET_SINGLE_CHALLENGE_PRIZES_INFO'
export const SET_SINGLE_CHALLENGE_PRIZES_DATA = 'SET_SINGLE_CHALLENGE_PRIZES_DATA'
export const SET_CHALLENGES_SETTINGS_DATA = 'SET_CHALLENGES_SETTINGS_DATA'
export const SET_LOADING = 'SET_LOADING'
export const SET_TASKS_LOADING = 'SET_TASKS_LOADING'
export const SET_TASKS_DATA = 'SET_TASKS_DATA'
export const SET_RESULTS_LOADING = 'SET_RESULTS_LOADING'
export const SET_TEAM_RESULTS_LOADING = 'SET_TEAM_RESULTS_LOADING'
export const SET_CHALLENGE_COMPLETED = 'SET_CHALLENGE_COMPLETED'
export const SET_QUIZ_COMPLETED = 'SET_QUIZ_COMPLETED'
export const SET_VIDEO_COMPLETED = 'SET_VIDEO_COMPLETED'
export const SET_READ_COMPLETED = 'SET_READ_COMPLETED'
export const SET_INTEGRATION_COMPLETED = 'SET_INTEGRATION_COMPLETED'
export const SET_CHALLENGE_COMPLETED_IMAGES = 'SET_CHALLENGE_COMPLETED_IMAGES'
export const SET_CHALLENGES_PAYMENT_DATA = 'SET_CHALLENGES_PAYMENT_DATA'
