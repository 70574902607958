export const stats = (state) => {
  return state.data
}

export const allStats = (state) => {
  return state.allData
}

export const activitiesChart = (state) => {
  return state.activitiesChart
}

export const todaysMood = (state) => {
  return state.todaysMood
}

export const allTransactions = (state) => {
  return state.allTransactions
}

export const transactionsBalance = (state) => {
  return state.transactionsBalance
}

export const errorConnectedProviders = (state) => {
  return state.errorConnectedProviders
}

export const errorStats = (state) => {
  return state.errorStats
}

export const errorAllStats = (state) => {
  return state.errorAllStats
}

export const errorFilteredStats = (state) => {
  return state.errorFilteredStats
}

export const healthscore = (state) => {
  const data = state.healthscore
  return data
}

export const scores = (state) => {
  return state.scores
}

export const scoresData = (state) => {
  return state.scoresData
}

export const wellbeingScore = (state) => {
  return state.wellbeingScore
}

export const learningScore = (state) => {
  return state.learningScore
}

export const socialScore = (state) => {
  return state.socialScore
}

export const goalsScore = (state) => {
  return state.goalsScore
}
