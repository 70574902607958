import * as api from '../api'
import * as types from './mutation-types'
import store from '@/store'

export const getStatsData = ({ commit }, { context }) => {
  context.errors = []
  context.isLoading = true
  api.getStatsData().then((response) => {
    commit(types.SET_STATS_DATA, response.data.data)
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const getAllStatsData = ({ commit }, { context, payload }) => {
  context.errors = []
  context.isLoading = true
  api.getAllStatsData(payload).then((response) => {
    commit(types.SET_ALL_STATS_DATA, response.data.data)
    context.isLoading = false
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const getFilteredStatsData = ({ commit }, { context, payload }) => {
  context.errors = []
  context.isLoading = true
  api.getFilteredStatsData(payload).then((response) => {
    commit(types.SET_ALL_STATS_DATA, response.data.data)
    context.isLoading = false
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const getMood = ({ commit }, { context }) => {
  api.getMood().then((response) => {
    let status = response.status
    if (status === 200) {
      let todaysMood = response.data.data
      commit(types.SET_TODAYS_MOOD, todaysMood)
    }
  })
}

export const postMood = ({ commit }, { payload, context }) => {
  context.isLoading = true
  context.success = false
  api.postMood(payload).then((response) => {
    // Mood posted!
    context.isLoading = false
    context.success = true

    // Elasticsearch delay
    setTimeout(function () {
      // Refresh daily to-do list
      store.dispatch('auth/getDailyTasks', { context: context })
    }, 2000)
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getAllTransactionsData = ({ commit }, { context, payload }) => {
  context.isLoading = true
  api.getAllTransactionsData(payload).then((response) => {
    commit(types.SET_ALL_TRANSACTIONS_DATA, response.data)
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const getTransactionsBalanceData = ({ commit }, { context, payload }) => {
  api.getTransactionsBalanceData(payload).then((response) => {
    commit(types.SET_TRANSACTIONS_BALANCE_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getHealthscoreData = ({ commit }, { context, payload }) => {
  api.getHealthscoreData(payload).then((response) => {
    commit(types.SET_HEALTHSCORE_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getScores = ({ commit }, { context, payload }) => {
  api.getScores(payload).then((response) => {
    commit(types.SET_SCORES_DATA, response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}
