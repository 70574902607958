import axios from 'axios'

export const getAllAchievementsData = (payload) => {
  let challengeId = payload.challengeId
  let perPage = payload.perPage
  let offset = payload.offset
  // let day = payload.day
  let task = payload.task
  let scoreId = payload.score_id
  let team = payload.team
  // let dayFilter = ''
  let taskFilter = ''
  let scoreFilter = ''
  let teamFilter = ''

  // if (!_.isNil(day)) {
  //   dayFilter = `filter[day]=${day}`
  // }

  if (!_.isNil(task)) {
    taskFilter = `filter[task]=${task}`
  }

  if (!_.isNil(scoreId)) {
    scoreFilter = `filter[score_id]=${scoreId}`
  }

  if (!_.isNil(team)) {
    teamFilter = `filter[team_id]=${team}`
  }

  return axios.get(`${process.env.API_URL}/v1/mc/challenges/achievements/all/${challengeId}?limit=${perPage}&offset=${offset}&${taskFilter}&${scoreFilter}&${teamFilter}`)
}

export const getSingleAchievementData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/achievements/${payload}`)
}

export const postComment = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/achievements/comment`, payload)
}

export const likeAchievement = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/achievements/like`, payload)
}

export const getCompanyTeamsData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/achievements/teams/${payload}`)
}
