const Mood = () => import(/* webpackChunkName: "Statistics" */ '../components/Mood')
const OverallStats = () => import(/* webpackChunkName: "Statistics" */ '../components/OverallStats')
const Chart = () => import(/* webpackChunkName: "Statistics" */ '../components/Chart')
const ReportsStats = () => import(/* webpackChunkName: "Statistics" */ '../components/ReportsStats')

export default [
  {
    path: '',
    name: 'mood',
    component: Mood
  },
  {
    path: '',
    name: 'overallStats',
    component: OverallStats
  },
  {
    path: '',
    name: 'chart',
    component: Chart
  },
  {
    path: '',
    name: 'reportsStats',
    component: ReportsStats
  }
]
