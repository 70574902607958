import axios from 'axios'

export const getAllChallengesData = (payload) => {
  let perPage = payload.perPage
  let offset = payload.offset
  let typePublic = payload.type
  let typeApplied = payload.applied
  let location = payload.location
  let filterPublic = ''
  let filterApplied = ''
  let filterLocation = ''

  if (!_.isNil(typePublic)) {
    filterPublic = `filter[public]=${typePublic}`
  }
  if (!_.isNil(typeApplied)) {
    filterApplied = `filter[applied]=${typeApplied}`
  }
  if (!_.isNil(location)) {
    filterLocation = `filter[location]=${location}`
  }

  return axios.get(`${process.env.API_URL}/v1/mc/challenges?limit=${perPage}&offset=${offset}&${filterPublic}&${filterApplied}&${filterLocation}`)
}

export const getSingleChallengeData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/${payload}`)
}

export const getDayTasks = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/${payload.challenge_id}/days/${payload.day_id}/tasks`)
}

export const updateChallenge = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/challenges`, payload)
}

export const createNewChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges`, payload)
}

export const deleteChallenge = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/mc/challenges/${payload}`)
}

export const applyToChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/apply`, payload)
}

export const unapplyFromChallenge = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/challenges/unapply`, payload)
}

export const completeChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete`, payload)
}

export const completeChallengeQR = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/qr`, payload)
}

export const completeChallengeText = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/text`, payload)
}

export const completeChallengeQuiz = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/quiz`, payload)
}

export const completeChallengeVideo1 = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/video-1`, payload)
}

export const completeChallengeVideo2 = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/video-2`, payload)
}

export const completeChallengeRead1 = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/read-1`, payload)
}

export const completeChallengeRead2 = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/read-2`, payload)
}

export const completeChallengeIntegrations = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/integrations`, payload)
}

export const changeCompletedImage = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/challenges/complete`, payload)
}

export const socialShareChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete/social`, payload)
}

export const getSingleChallengeResultsInfoData = (payload) => {
  let challengeId = payload.challengeId
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/results/${challengeId}/data`)
}

export const getSingleChallengeResultsData = (payload) => {
  let challengeId = payload.challengeId
  let perPage = payload.perPage
  let offset = payload.offset
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/results/${challengeId}?limit=${perPage}&offset=${offset}`)
}

export const getSingleChallengeTeamResultsData = (payload) => {
  let challengeId = payload.challengeId
  let teamsLevel = payload.teamsLevel
  let perPage = payload.perPage
  let offset = payload.offset
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/results/${challengeId}/teams/${teamsLevel}?limit=${perPage}&offset=${offset}`)
}

export const getSingleChallengePrizesInfo = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/prizes/info/${payload}`)
}

export const getSingleChallengePrizesData = (payload) => {
  let challengeId = payload.challengeId
  let perPage = payload.perPage
  let offset = payload.offset
  let category = payload.category_id
  let categoryFilter = ''

  if (!_.isNil(category)) {
    categoryFilter = `filter[category_id]=${category}`
  }

  return axios.get(`${process.env.API_URL}/v1/mc/challenges/prizes/data/${challengeId}?limit=${perPage}&offset=${offset}&${categoryFilter}`)
}

export const getChallengeCompletedImages = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/completed/${payload}`)
}

export const getChallengesSettingsData = () => {
  return axios.get(`${process.env.API_URL}/v1/mc/settings/challenges`)
}

export const changeChallengeSettings = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/settings/challenges`, payload)
}

export const getChallengePaymentRedirect = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/payment/ch/${payload.challengeId}?url=${payload.returnUrl}`)
}

export const paymentStart = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/payment/ch/${payload.challengeId}/start`)
}

export const paymentFinish = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/payment/ch/${payload.challengeId}/finish`, payload)
}
