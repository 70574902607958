import * as api from '../api'
import * as types from './mutation-types'

export const getMiniBranding = ({ commit }, { context }) => {
  api.getMiniBranding().then((response) => {
    commit(types.SET_MINI_BRANDING, response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}
