import * as api from '../api/index'
import * as types from './mutation-types'
import router from '@/router'

export const getAllChallengesData = ({ commit }, { context, payload }) => {
  context.isLoading = true
  api.getAllChallengesData(payload).then((response) => {
    commit(types.SET_ALL_CHALLENGES_DATA, response.data)
    context.isLoading = false
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeData = ({ commit }, { context, payload }) => {
  commit(types.SET_LOADING, true)
  api.getSingleChallengeData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_DATA, response.data)
    commit(types.SET_LOADING, false)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getDayTasks = ({ commit }, { context, payload }) => {
  commit(types.SET_TASKS_LOADING, true)
  api.getDayTasks(payload).then((response) => {
    commit(types.SET_TASKS_DATA, response.data.data)
    commit(types.SET_TASKS_LOADING, false)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const createNewChallenge = ({ dispatch }, { context, payload }) => {
  api.createNewChallenge(payload).then((response) => {
    dispatch('getAllChallengesData', { context: this })
    let challengeId = response.data.data.id
    router.push({name: 'mini.company.challenges.single.edit', params: { challengeId: challengeId}})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deleteChallenge = ({ dispatch }, { context, payload }) => {
  api.deleteChallenge(payload).then((response) => {
    router.push({name: 'dashboard.company.challenges'})
    dispatch('getAllChallengesData', { context: this })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const applyToChallenge = ({ dispatch }, { context, payload }) => {
  api.applyToChallenge(payload).then((response) => {
    let challengeId = payload.challenge_id
    dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    context.success = true
    router.push({
      name: 'mini.challenges.single.home',
      params: { challengeId: challengeId }
    })
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const unapplyFromChallenge = ({ dispatch }, { context, payload }) => {
  api.unapplyFromChallenge(payload).then((response) => {
    dispatch('getAllChallengesData', { context: this })
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeResultsInfoData = ({ commit }, { context, payload }) => {
  api.getSingleChallengeResultsInfoData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_RESULTS_INFO_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeResultsData = ({ commit }, { context, payload }) => {
  commit(types.SET_RESULTS_LOADING, true)
  api.getSingleChallengeResultsData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_RESULTS_DATA, response.data)
    commit(types.SET_RESULTS_LOADING, false)
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    commit(types.SET_RESULTS_LOADING, false)
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeTeamResultsData = ({ commit }, { context, payload }) => {
  commit(types.SET_TEAM_RESULTS_LOADING, true)
  api.getSingleChallengeTeamResultsData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_TEAM_RESULTS_DATA, response.data)
    commit(types.SET_TEAM_RESULTS_LOADING, false)
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    commit(types.SET_TEAM_RESULTS_LOADING, false)
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengePrizesInfo = ({ commit }, { context, payload }) => {
  api.getSingleChallengePrizesInfo(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_PRIZES_INFO, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengePrizesData = ({ commit }, { context, payload }) => {
  api.getSingleChallengePrizesData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_PRIZES_DATA, response.data)
    context.total = response.data.meta.pagination.total
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallenge = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallenge(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle

    if (context.social_share) {
      completedData.social_share = context.social_share
    }

    commit(types.SET_CHALLENGE_COMPLETED, completedData)

    context.social_share = false
    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
    context.openTaskComplete()
    // location.reload()
  }).catch((error) => {
    console.log('error', error)
    context.errors = error.response.data.errors
  })
}

export const completeChallengeQR = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeQR(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle

    commit(types.SET_CHALLENGE_COMPLETED, completedData)

    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
    context.openTaskComplete()
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallengeText = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeText(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle

    commit(types.SET_CHALLENGE_COMPLETED, completedData)

    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
    context.openTaskComplete()
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallengeQuiz = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeQuiz(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle

    commit(types.SET_QUIZ_COMPLETED, completedData)

    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
    context.openTaskComplete()
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallengeVideo1 = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeVideo1(payload).then((response) => {
    // Nothing
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallengeVideo2 = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeVideo2(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle

    commit(types.SET_VIDEO_COMPLETED, completedData)
    context.openTaskComplete()

    // let challengeId = payload.challenge_id
    // dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallengeRead1 = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeRead1(payload).then((response) => {
    // Nothing
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallengeRead2 = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeRead2(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle

    // commit(types.SET_READ_COMPLETED, completedData)
    commit(types.SET_CHALLENGE_COMPLETED, completedData)

    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
    context.openTaskComplete()
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeChallengeIntegrations = ({ dispatch, commit }, { context, payload }) => {
  api.completeChallengeIntegrations(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data

    commit(types.SET_INTEGRATION_COMPLETED, completedData)

    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
    context.openTaskComplete()
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const changeCompletedImage = ({ dispatch, commit }, { context, payload }) => {
  api.changeCompletedImage(payload).then((response) => {
    context.success = true

    let completedData = {}
    completedData = response.data.data
    completedData.title = context.completeTitle
    completedData.day = context.completeDay

    if (context.social_share) {
      completedData.social_share = context.social_share
    }

    commit(types.SET_CHALLENGE_COMPLETED, completedData)

    context.social_share = false
    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const socialShareChallenge = ({ dispatch, commit }, { context, payload }) => {
  api.socialShareChallenge(payload).then((response) => {
    context.success = true
    context.social_shared = true
    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const clearChallengeCompleted = ({ commit }, { context }) => {
  commit(types.SET_CHALLENGE_COMPLETED, {})
  commit(types.SET_QUIZ_COMPLETED, {})
  commit(types.SET_VIDEO_COMPLETED, {})
  commit(types.SET_READ_COMPLETED, {})
  commit(types.SET_CHALLENGE_COMPLETED_IMAGES, {})
  commit(types.SET_INTEGRATION_COMPLETED, {})
}

export const getChallengeCompletedImages = ({ commit }, { context, payload }) => {
  api.getChallengeCompletedImages(payload).then((response) => {
    commit(types.SET_CHALLENGE_COMPLETED_IMAGES, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getChallengesSettingsData = ({ commit }, { context }) => {
  context.isLoadingChallenges = true
  api.getChallengesSettingsData().then((response) => {
    commit(types.SET_CHALLENGES_SETTINGS_DATA, response.data)
    context.isLoadingChallenges = false
  }).catch((error) => {
    context.isLoadingChallenges = false
    context.errorsChallenges = error.response.data.errors
  })
}

export const changeChallengeSettings = ({ dispatch }, { context, payload }) => {
  context.successChallenges = false
  context.errorsChallenges = []
  api.changeChallengeSettings(payload).then((response) => {
    // dispatch('getChallengesSettingsData', { context: context })
    context.successChallenges = true
  }).catch((error) => {
    context.errorsChallenges = error.response.data.errors
  })
}

export const paymentStart = ({ commit }, { context, payload }) => {
  api.paymentStart(payload).then((response) => {
    commit(types.SET_CHALLENGES_PAYMENT_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const paymentFinish = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  api.paymentFinish(payload).then((response) => {
    // commit(types.SET_CHALLENGES_SETTINGS_DATA, response.data)

    context.success = true
    context.isLoading = false
    let payload2 = {
      challenge_id: payload.challenge_id,
      day_id: payload.day_id,
    }
    dispatch('getDayTasks', { payload: payload2, context: context })

    // if (response.data.redirect) {
    //   // Redirect for 3D Secure payment, to confirm payment
    //   window.location = response.data.redirect
    // } else {
    //   // No 3D Secure confirmation needed
    //   context.success = true
    //   context.isLoading = false
    //   let challengeId = payload.challengeId
    //   dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    // }


    // window.location.href = window.location.href + '?payment=success'
  }).catch((error) => {
    context.isLoading = false
    console.log(error.response.data.errors)
    context.errors = error.response.data.errors
  })
  // return api.getChallengePaymentRedirect(payload).then((response) => {
  //   context.success = true
  //   if (response.data.payUrl) {
  //     window.location = response.data.payUrl
  //   }
  // }).catch((error) => {
  //   context.isLoading = false
  //   context.errors = error.response.data.errors
  // })
}
