const Home = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/Home')
const About = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/About')
const Results = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/Results')
const Achievements = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/Achievements')
const Partners = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/Partners')
const Prizes = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/Prizes')
const Redirect = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/Redirect')
const Terms = () => import(/* webpackChunkName: "MiniChallenges" */ '../components/Terms')

// Children
import MiniAchievements from '@/app/mini-achievements/routes'

export default [
  {
    path: '',
    name: 'mini.challenges.single.home',
    component: Home,
    meta: {
      needsAuth: true
    }
  },
  {
    path: 'about',
    name: 'mini.challenges.single.about',
    component: About,
    meta: {
      needsAuth: true
    }
  },
  {
    path: 'results',
    name: 'mini.challenges.single.results',
    component: Results,
    meta: {
      needsAuth: true
    }
  },
  {
    path: 'achievements',
    name: 'mini.challenges.single.achievements',
    component: Achievements,
    children: MiniAchievements,
    meta: {
      needsAuth: true
    }
  },
  {
    path: 'partners',
    name: 'mini.challenges.single.partners',
    component: Partners,
    meta: {
      needsAuth: true
    }
  },
  {
    path: 'prizes',
    name: 'mini.challenges.single.prizes',
    component: Prizes,
    meta: {
      needsAuth: true
    }
  },
  {
    path: 'redirect',
    name: 'mini.challenges.single.redirect',
    component: Redirect
  },
  {
    path: 'terms',
    name: 'mini.challenges.single.terms',
    component: Terms
  }
]
