import Vue from 'vue'

import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import components from '@/app/global/components/'
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import i18next from 'i18next'
import VueAuthenticate from 'vue-authenticate'
import VueBreadcrumbs from 'vue-breadcrumbs'
import VueI18next from '@panter/vue-i18next'
import VueI18nextXHR from 'i18next-xhr-backend'
import Vuelidate from 'vuelidate'
import { IDB_DOMAIN, LOCALE_DEFAULT, LOCALE_FALLBACK, FB_APP_ID, FB_APP_SECRET, GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECRET, MIXPANEL_TOKEN } from '@/util/constants'
import { Settings } from 'luxon'
import lodash from 'lodash'
import VueLodash from 'vue-lodash'
import Paginate from 'vuejs-paginate'
import VueProgressBar from 'vue-progressbar'
import VueClipboard from 'vue-clipboard2'
import VueMixpanel from 'vue-mixpanel'

Settings.defaultLocale = LOCALE_DEFAULT

Vue.prototype.$http = axios

Object.keys(components).forEach(tag => Vue.component(tag, components[tag]))

Vue.use(BootstrapVue)
Vue.use(Datetime)

Vue.use(VueAuthenticate, {
  baseUrl: `${process.env.API_URL}/v1/login/facebook/no-token`, // API Endpoint
  providers: {
    facebook: {
      url: '/oauth/facebook',
      clientId: FB_APP_ID,
      clientSecret: FB_APP_SECRET,
      redirectUri: `https://my.${IDB_DOMAIN}`,
      responseType: 'code',
      scope: 'public_profile,email',
      state: { identity: 'empty' },
      display: 'iframe'
    },
    google: {
      clientId: GOOGLE_CLIENT_ID,
      clientSecret: GOOGLE_CLIENT_SECRET,
      redirectUri: `https://api.${IDB_DOMAIN}/v1/login/google`
    }
  }
})

Vue.use(VueBreadcrumbs)

Vue.use(VueI18next)

// loadPath: `https://private-bd283-efectio2.apiary-mock.com/v1/locales/{{lng}}/translation`,
i18next.use(VueI18nextXHR).init({
  backend: {
    loadPath: `${process.env.API_URL}/v1/locales/{{lng}}/translation`,
    crossDomain: true
  },
  debug: false,
  defaultNS: 'translation',
  fallbackLng: LOCALE_FALLBACK,
  lng: LOCALE_DEFAULT,
  ns: ['translation']
}, function (t) {
  // Initialized
})

export const i18n = new VueI18next(i18next)

Vue.use(Vuelidate)

Vue.use(VueLodash, lodash)

Vue.component('paginate', Paginate)

const progressBarOptions = {
  color: '#00edd3',
  failedColor: '#ff5757',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, progressBarOptions)

Vue.use(VueClipboard)

Vue.use(VueMixpanel, {
  token: MIXPANEL_TOKEN
})
