import Debug from '@/util/debug'
import { updateTitle } from '@/util/dom'

const debug = Debug('router:afterEach')

const afterEach = (to) => {
  debug.info('navigated to %s', to.fullPath)
  updateTitle()
  return Promise.resolve()
}

export default afterEach
