import axios from 'axios'

export const getAllEmployeesData = () => {
  return axios.get(`${process.env.API_URL}/v1/company/employees/`)
}

export const getSingleEmployeeData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/company/employees/${payload}`)
}

export const getSingleEmployeeCharts = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/company/employees/${payload.id}/charts`, {
    params: {
      start: payload.start,
      finish: payload.finish,
      active: payload.active
    }
  })
}

export const getSingleEmployeeResults = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/company/employees/${payload}/results`)
}

export const updateEmployee = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/company/employees`, payload)
}

export const deleteEmployee = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/company/employees/${payload}`)
}

export const addEmployee = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/company/employees`, payload)
}

export const addBulkEmployees = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/company/employees/csv`, payload)
}

export const getAllTransactionsData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/company/transactions/employees/${payload.id}`, {
    params: {
      start: payload.start,
      finish: payload.finish,
      activity: payload.activity
    }
  })
}

export const getTransactionsBalanceData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/company/transactions/employees/${payload.id}/balance`)
}

export const transferPoints = (payload) => {
  // Empty for now
}
