const Single = () => import(/* webpackChunkName: "MiniAdminChallenges" */ '../components/Single')
const Preview = () => import(/* webpackChunkName: "MiniAdminChallenges" */ '../components/PreviewSingle')
const Lottery = () => import(/* webpackChunkName: "MiniAdminChallenges" */ '../components/LotterySingle')

export default [
  {
    path: ':challengeId(\\d+)',
    name: 'mini.company.challenges.single.edit',
    component: Single,
    meta: {
      dashboard: 'company',
      breadcrumb: 'Challenges'
    }
  },
  {
    path: 'create',
    name: 'mini.company.challenges.create',
    component: Single,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Challenges'
    }
  },
  {
    path: ':challengeId(\\d+)/preview',
    name: 'mini.company.challenges.preview',
    component: Preview,
    meta: {
      dashboard: 'company',
      breadcrumb: 'Challenges'
    }
  },
  {
    path: ':challengeId(\\d+)/lottery/:lotteryId(\\d+)',
    name: 'mini.company.challenges.lottery',
    component: Lottery,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Challenges'
    }
  }
]
