export const isLoading = state => state.loading

export const isResultsLoading = state => state.loadingResults

export const data = (state) => {
  return state.data
}

export const allChallenges = (state) => {
  const data = state.allChallenges.data
  return data
}

export const singleChallenge = (state) => {
  const data = state.singleChallenge.data
  return data
}

export const singleChallengePrizesInfo = (state) => {
  const data = state.singleChallengePrizesInfo.data
  return data
}

export const singleChallengePrizes = (state) => {
  const data = state.singleChallengePrizes.data
  return data
}

export const singleChallengeResults = (state) => {
  const data = state.singleChallengeResults
  return data
}

export const challengesSettings = (state) => {
  const data = state.challengesSettings.data
  return data
}

export const challengeCompleted = (state) => {
  return state.challengeCompleted
}

export const challengeCompletedImages = (state) => {
  return state.challengeCompletedImages
}

export const companyTeams = (state) => {
  const data = state.companyTeams.data
  return data
}

export const companyTeamEmployees = (state) => {
  const data = state.companyTeamEmployees
  return data
}

export const companyBranding = (state) => {
  const data = state.companyBranding
  return data
}
