export const isLoading = state => state.loading

export const isResultsLoading = state => state.loadingResults

export const isTeamResultsLoading = state => state.loadingTeamResults

export const data = (state) => {
  return state.data
}

export const allChallenges = (state) => {
  const data = state.allChallenges.data
  return data
}

export const singleChallenge = (state) => {
  const data = state.singleChallenge.data
  return data
}

export const dayTasks = (state) => {
  return state.dayTasks
}

export const tasksLoading = (state) => {
  return state.tasksLoading
}

export const singleChallengePrizesInfo = (state) => {
  const data = state.singleChallengePrizesInfo.data
  return data
}

export const singleChallengePrizes = (state) => {
  const data = state.singleChallengePrizes.data
  return data
}

export const singleChallengeResultsInfo = (state) => {
  const data = state.singleChallengeResultsInfo.data
  return data
}

export const singleChallengeResults = (state) => {
  const data = state.singleChallengeResults
  return data
}

export const singleChallengeTeamResults = (state) => {
  const data = state.singleChallengeTeamResults
  return data
}

export const challengesSettings = (state) => {
  const data = state.challengesSettings.data
  return data
}

export const challengeCompleted = (state) => {
  return state.challengeCompleted
}

export const quizCompleted = (state) => {
  return state.quizCompleted
}

export const videoCompleted = (state) => {
  return state.videoCompleted
}

export const readCompleted = (state) => {
  return state.readCompleted
}

export const integrationCompleted = (state) => {
  return state.integrationCompleted
}

export const challengeCompletedImages = (state) => {
  return state.challengeCompletedImages
}

export const challengesPayment = (state) => {
  return state.challengesPayment
}
