const Single = () => import(/* webpackChunkName: "MiniAchievements" */ '../components/Single')

export default [
  {
    path: ':achievementId(\\d+)',
    name: 'mini.challenges.single.achievements.single',
    component: Single,
    meta: {
      needsAuth: true
    }
  }
]
