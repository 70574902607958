import * as api from '../api/index'
import * as types from './mutation-types'

export const getAllAchievementsData = ({ commit }, { context, payload }) => {
  commit(types.SET_LOADING, true)
  api.getAllAchievementsData(payload).then((response) => {
    commit(types.SET_ALL_ACHIEVEMENTS_DATA, response.data)
    commit(types.SET_LOADING, false)
    // context.total = response.data.meta.pagination.total
    commit(types.SET_TOTAL_ACHIEVEMENTS, response.data.meta.pagination.total)
    let scoreId = payload.score_id
    if (!_.isNil(scoreId)) {
      commit(types.SET_USER_SCORE, scoreId)
    }
    context.currentPage = response.data.meta.pagination.currentPage
    context.lastPage = response.data.meta.pagination.lastPage
  }).catch((error) => {
    commit(types.SET_LOADING, false)
    context.errors = error.response.data.errors
  })
}

export const getSingleAchievementData = ({ commit }, { context, payload }) => {
  commit(types.SET_LOADING, true)
  api.getSingleAchievementData(payload).then((response) => {
    commit(types.SET_SINGLE_ACHIEVEMENT_DATA, response.data)
    commit(types.SET_LOADING, false)
  }).catch((error) => {
    commit(types.SET_LOADING, false)
    context.errors = error.response.data.errors
  })
}

export const postComment = ({ dispatch }, { context, payload }) => {
  api.postComment(payload).then((response) => {
    context.userComment = null
    dispatch('getSingleAchievementData', { context: this, payload: payload.achievement_id })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const likeAchievement = ({ dispatch, commit }, { context, payload }) => {
  api.likeAchievement(payload).then((response) => {
    let achievementId = payload.achievement_id
    let userLiked = payload.like
    let likesCount = response.data.data.likes_count
    let likes = response.data.data.likes
    let points = response.data.data.points

    let achievement = {
      id: achievementId,
      likes_count: likesCount,
      user_liked: userLiked,
      points: points
    }
    commit(types.EDIT_ACHIEVEMENT_DATA, achievement)
    // commit(types.SET_SINGLE_ACHIEVEMENT_DATA, achievement)

    // alert(likes_count)
    context.likes_count = likesCount
    context.likes = likes
    context.points = points
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getCompanyTeamsData = ({ commit }, { context, payload }) => {
  api.getCompanyTeamsData(payload).then((response) => {
    commit(types.SET_COMPANY_TEAMS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}
