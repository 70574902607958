export default {
  data: {},
  allData: {},
  activitiesChart: {
    steps: {
      data: [],
      labels: []
    },
    calories: {
      data: [],
      labels: []
    },
    distance: {
      data: [],
      labels: []
    }
  },
  todaysMood: {},
  allTransactions: {},
  transactionsBalance: {},
  errorConnectedProviders: null,
  errorStats: null,
  errorAllStats: null,
  errorFilteredStats: null,
  healthscore: {},
  scores: {},
  scoresData: [0, 0, 0, 0],
  wellbeingScore: '',
  learningScore: '',
  socialScore: '',
  goalsScore: ''
}
