const Login = () => import(/* webpackChunkName: "Auth" */ '../components/Login')
const SignUpRedirect = () => import(/* webpackChunkName: "Auth" */ '../components/SignUpRedirect')
const ForgotRedirect = () => import(/* webpackChunkName: "Auth" */ '../components/ForgotRedirect')
const SocialRedirect = () => import(/* webpackChunkName: "Auth" */ '../components/SocialRedirect')
const SignUp = () => import(/* webpackChunkName: "Auth" */ '../components/SignUp')
const CompanyHome = () => import(/* webpackChunkName: "Auth" */ '../components/CompanyHome')
const SignUpDemoRedirect = () => import(/* webpackChunkName: "Auth" */ '../components/SignUpDemoRedirect')
const Forgot = () => import(/* webpackChunkName: "Auth" */ '../components/Forgot')
const Deleted = () => import(/* webpackChunkName: "Auth" */ '../components/Deleted')
const ChooseCompany = () => import(/* webpackChunkName: "Auth" */ '../components/ChooseCompany')

// Children
import SignUpSteps from '@/app/auth-steps/routes/signup-steps'
import ForgotSteps from '@/app/auth-steps/routes/forgot-steps'

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/login/success',
    name: 'login.success',
    component: Login,
    meta: { refreshToken: true }
  },
  {
    path: '/register/:code',
    name: 'register',
    component: SignUpRedirect,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    props: true,
    children: SignUpSteps,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/signup/:secret',
    name: 'signup-secret',
    component: SignUp,
    props: true,
    children: SignUpSteps,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/signup-company',
    name: 'signup.company',
    component: CompanyHome,
    meta: { guest: false, needsAuth: false }
  },
  {
    path: '/signup-demo',
    name: 'signup.demo',
    component: SignUpDemoRedirect,
    meta: { guest: false, needsAuth: false }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot,
    props: true,
    children: ForgotSteps,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/forgot/:code',
    name: 'forgot.redirect',
    component: ForgotRedirect,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/profile/:code',
    name: 'deleted',
    component: Deleted,
    props: true
  },
  {
    path: '/social',
    name: 'social',
    component: SocialRedirect,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/choose-company',
    name: 'choose.company',
    component: ChooseCompany,
    meta: { guest: false, needsAuth: true }
  }
]
