import {DOCUMENT_TITLE} from './constants'

let currentTitle = DOCUMENT_TITLE

export function updateTitle (newTitle) {
  if (typeof newTitle !== 'undefined') {
    currentTitle = newTitle
  }
  document.title = currentTitle
  window.history.replaceState({}, document.title, document.location.href)
}

export function updateFavicon (notification = false) {
  // Default favicon is for light mode
  let favicon = 'https://questario.s3-eu-west-1.amazonaws.com/images/questario-favicon.png'

  // Light mode favicon with notifications icon
  if (notification) {
    favicon = 'https://questario.s3-eu-west-1.amazonaws.com/images/questario-favicon-notification.png'
  }
  // Check if browser has dark mode
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // Dark mode
    favicon = 'https://questario.s3-eu-west-1.amazonaws.com/images/questario-favicon.png'

    // White favicon with notifications icon, for dark mode
    if (notification) {
      favicon = 'https://questario.s3-eu-west-1.amazonaws.com/images/questario-favicon-notification.png'
    }
  }
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = favicon;
  document.getElementsByTagName('head')[0].appendChild(link)
}

export function scrollTo (element, to, duration) {
  let start = element.scrollTop
  let change = to - start
  let currentTime = 0
  let increment = 2

  var animateScroll = function() {
    currentTime += increment
    let val = Math.easeInOutQuad(currentTime, start, change, duration)
    element.scrollTop = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  };
  animateScroll()
}

/*
  t = current time
  b = start value
  c = change in value
  d = duration
*/
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2
  if (t < 1) return c/2*t*t + b
  t--
  return -c/2 * (t*(t-2) - 1) + b
}
