import axios from 'axios'

export const getAllChallengesData = () => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges`)
}

export const getSingleChallengeData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/${payload}`)
}

export const updateChallenge = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/challenges`, payload)
}

export const createNewChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges`, payload)
}

export const deleteChallenge = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/mc/challenges/${payload}`)
}

export const applyToChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/apply`, payload)
}

export const unapplyFromChallenge = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/challenges/unapply`, payload)
}

export const completeChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/challenges/complete`, payload)
}

export const changeCompletedImage = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/challenges/complete`, payload)
}

export const getSingleChallengeResultsData = (payload) => {
  let challengeId = payload.challengeId
  let perPage = payload.perPage
  let offset = payload.offset
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/results/${challengeId}?limit=${perPage}&offset=${offset}`)
}

export const getSingleChallengePrizesInfo = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/prizes/info/${payload}`)
}

export const getSingleChallengePrizesData = (payload) => {
  let challengeId = payload.challengeId
  let perPage = payload.perPage
  let offset = payload.offset
  let category = payload.category_id
  let categoryFilter = ''

  if (!_.isNil(category)) {
    categoryFilter = `filter[category_id]=${category}`
  }

  return axios.get(`${process.env.API_URL}/v1/mc/challenges/prizes/data/${challengeId}?limit=${perPage}&offset=${offset}&${categoryFilter}`)
}

export const getChallengeCompletedImages = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/challenges/completed/${payload}`)
}

export const getChallengesSettingsData = () => {
  return axios.get(`${process.env.API_URL}/v1/mc/settings/challenges`)
}

export const changeChallengeSettings = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/settings/challenges`, payload)
}

export const getCompanyTeams = () => {
  return axios.get(`${process.env.API_URL}/v1/company/teams`)
}

export const createCompanyTeam = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/company/teams`, payload)
}

export const updateCompanyTeam = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/company/teams`, payload)
}

export const updateCompanyTeamsOrder = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/company/teams/order`, payload)
}

export const getCompanyTeamEmployees = (payload) => {
  let teamId = payload.teamId
  let perPage = payload.perPage
  let offset = payload.offset
  return axios.get(`${process.env.API_URL}/v1/company/teams/employees/${teamId}?limit=${perPage}&offset=${offset}`)
}

export const updateCompanyTeamEmployees = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/company/teams/employees`, payload)
}

export const createCompany = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/company/account`, payload)
}

export const updateCompany = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/company/account`, payload)
}

export const getCompanyBranding = () => {
  return axios.get(`${process.env.API_URL}/v1/company/branding`)
}

export const updateCompanyBranding = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/company/branding`, payload)
}
