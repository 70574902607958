<template>
    <div class="spacer" :class="computedClass"></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    size: {
      type: Number,
      default: 9
    }
  },
  computed: {
    computedClass () {
      let classes = []

      classes.push(this.color)
      classes.push('w-' + this.size)

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
  .pink {
    background-color: $brand-danger;
  }

  .yellow {
    background-color: $brand-warning;
  }

  .green {
    background-color: $form-valid-color;
  }

  .blue {
    background-color: $brand-primary;
  }

  .grey {
    background-color: $light-grey;
  }

  .spacer {
    // @extend .my-4;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
