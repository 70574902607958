const SingleAdd = () => import(/* webpackChunkName: "MiniAdminEmployees" */ '../components/SingleAdd')
const BulkImport = () => import(/* webpackChunkName: "MiniAdminEmployees" */ '../components/BulkImport')
const SingleEdit = () => import(/* webpackChunkName: "MiniAdminEmployees" */ '../components/SingleEdit')

export default [
  {
    path: ':employeeId(\\d+)',
    name: 'mini.company.employees.single.edit',
    component: SingleEdit,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Employees'
    }
  },
  {
    path: 'add',
    name: 'mini.company.employees.single.add',
    component: SingleAdd,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Employees'
    }
  },
  {
    path: 'import',
    name: 'mini.company.employees.import',
    component: BulkImport,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Employees'
    }
  }
]
