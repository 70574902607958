import * as types from './mutation-types'

export default {
  [types.SET_ALL_CHALLENGES_DATA] (state, data) {
    state.allChallenges = data
  },
  [types.SET_SINGLE_CHALLENGE_DATA] (state, data) {
    state.singleChallenge = data
  },
  [types.SET_SINGLE_CHALLENGE_PRIZES_INFO] (state, data) {
    state.singleChallengePrizesInfo = data
  },
  [types.SET_SINGLE_CHALLENGE_PRIZES_DATA] (state, data) {
    state.singleChallengePrizes = data
  },
  [types.SET_SINGLE_CHALLENGE_RESULTS_DATA] (state, data) {
    state.singleChallengeResults = data
  },
  [types.SET_CHALLENGES_SETTINGS_DATA] (state, data) {
    state.challengesSettings = data
  },
  [types.SET_LOADING] (state, trueOrFalse) {
    state.loading = trueOrFalse
  },
  [types.SET_RESULTS_LOADING] (state, trueOrFalse) {
    state.loadingResults = trueOrFalse
  },
  [types.SET_CHALLENGE_COMPLETED] (state, data) {
    state.challengeCompleted = data
  },
  [types.SET_CHALLENGE_COMPLETED_IMAGES] (state, data) {
    state.challengeCompletedImages = data
  },
  [types.SET_COMPANY_TEAMS_DATA] (state, data) {
    state.companyTeams = data
  },
  [types.SET_COMPANY_TEAM_EMPLOYEES_DATA] (state, data) {
    state.companyTeamEmployees = data
  },
  [types.SET_COMPANY_BRANDING_DATA] (state, data) {
    state.companyBranding = data
  }
}
