import Vue from 'vue'
import * as api from '../api'
import * as storage from '@/util/storage'
import * as types from './mutation-types'
import * as getters from './getters'
import _ from 'lodash'
import { setHttpToken } from '@/util/api'
import router from '@/router'
import { IDB_DOMAIN, ENV_IS_PRODUCTION } from '@/util/constants'
import i18next from 'i18next'
import firebase from 'firebase'
import { browserName, detectIE } from '@/util/browser'
import store from '@/store'

export const registerEmail = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  return api.registerEmail(payload).then((response) => {
    router.push({ name: 'signup.step1.email', params: { newCompany: payload.new_company } })
    context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const registerAccount = ({ dispatch }, { payload, context }) => {
  context.isLoading = true
  let newCompany = false
  if (!_.isUndefined(payload.new_company) && payload.new_company) {
    newCompany = true
  }
  return api.registerAccount(payload).then((response) => {
    dispatch('setToken', response.data.data.access_token).then(() => {
      let slug = {}
      dispatch('fetchUser', { context: context }).then(() => {
        // if (!_.isNil(slug)) {
        //   dispatch('fetchCompany', { payload: slug })
        // }
        dispatch('fetchCurrentCompany') // Grabs company slug from global getter
        context.errors = []

        if (newCompany) {
          router.replace({ name: 'signup.step3', params: { newCompany: newCompany }})
        } else {
          router.replace({name: 'login.success'})
        }
      })
    })
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getUserByCode = ({ commit, dispatch }, code) => {
  return api.getUserByCode(code).then((response) => {
    commit(types.SET_USER_DATA, {
      code: code,
      first_name: response.data.data.first_name,
      last_name: response.data.data.last_name,
      email: response.data.data.email,
      company_name: response.data.data.company_name,
      slug: response.data.data.slug,
      new_company: response.data.data.new_company,
      company_secret: response.data.data.company_secret
    })
  })
}

export const chooseCompany = ({ dispatch }, { payload, context }) => {
  context.isLoading = true
  dispatch('fetchUser', { context: context }).then(() => {
    let afterLoginPage = store.getters['global/afterLoginPage']
    router.replace({name: afterLoginPage})
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const login = ({ dispatch }, { payload, context }) => {
  context.isLoading = true
  return api.oAuthLogin(payload).then((response) => {
    dispatch('setToken', response.data.data.access_token).then(() => {
      let slug = {}
      dispatch('fetchUser', { context: context }).then(() => {
        // if (!_.isNil(slug)) {
        //   dispatch('fetchCompany', { payload: slug })
        // }
        dispatch('fetchCurrentCompany') // Grabs company slug from global getter
        context.errors = []

        // Internet Explorer and Safari doesn't support web push notifications
        if (firebase.messaging.isSupported()) {

          // Start of Firebase Cloud Messaging for notifications
          var firebaseConfig = {
            apiKey: "AIzaSyBOhwK4fftTwHKcEJN2QVW6rhPn7-SsdCM",
            authDomain: "questario.firebaseapp.com",
            projectId: "questario",
            storageBucket: "questario.appspot.com",
            messagingSenderId: "1082971002628",
            appId: "1:1082971002628:web:8603fd6435cb5608d76251",
            measurementId: "G-7YEZF6W9L3"
          }

          if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig)
            // firebase.analytics()
          }

          Vue.prototype.$messaging = firebase.messaging()

          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/static/firebase-messaging-sw.js', {
              scope: '/static/'
            })
              .then(function (reg) {
                Vue.prototype.$messaging.useServiceWorker(reg)

                Vue.prototype.$messaging
                  .requestPermission()
                  .then(() => Vue.prototype.$messaging.getToken())
                  .then((token) => {
                    let payload = {
                      token: token,
                      device_registered: false,
                      type: browserName()
                    }
                    dispatch('sendNotificationsToken', {payload: payload, context: this})
                  })
                  .catch(function (err) {
                    // console.log('Unable to get permission to notify.', err)
                  });

                Vue.prototype.$messaging.onMessage(function (payload) {
                  // console.log('Message received. ', payload)
                  // ...
                })
              }).catch(function (error) {
              // registration failed
              // console.log('Registration failed with ' + error)
            })
          }
        }
        // End of Firebase Cloud Messaging for notifications

        router.replace({name: 'login.success'})
      })
      context.isLoading = false
    }).catch((error) => {
      context.isLoading = false
      context.errors = error.response.data.errors
    })
    context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const logout = ({ dispatch }) => {
  return api.logout().then((response) => {
    dispatch('clearAuth').then(() => {
      router.replace({name: 'home'})
    })
  }).catch((error) => {
    dispatch('clearAuth')
    router.replace({name: 'home'})
  })
}

export const socialLogin = ({ dispatch }, { context, payload }) => {
  return api.socialLogin(payload).then((response) => {
    context.success = true
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const socialRegister = ({ dispatch }, { context, payload }) => {
  return api.socialRegister(payload).then((response) => {
    context.success = true
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const socialLoginToken = ({ dispatch }, { payload, context }) => {
  dispatch('clearAuth').then(() => {
    dispatch('setToken', payload).then(() => {
      let slug = {}
      dispatch('fetchUser', { context: context }).then(() => {
        // if (!_.isNil(slug)) {
        //   dispatch('fetchCompany', { payload: slug })
        // }
        dispatch('fetchCurrentCompany') // Grabs company slug from global getter
        context.errors = []
        router.replace({name: 'login.success'})
      })
    }).catch((error) => {
      context.errors = error.response.data.errors
    })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const sendForgotEmail = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  return api.sendForgotEmail(payload).then((response) => {
    router.push({name: 'forgot.step1.email'})
    context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getForgotDataByCode = ({ commit, dispatch }, code) => {
  return api.getForgotDataByCode(code).then((response) => {
    commit(types.SET_USER_DATA, {
      code: code,
      email: response.data.data.email
    })
  })
}

export const changePassword = ({ dispatch }, { context, payload }) => {
  return api.changePassword(payload).then((response) => {
    router.push({name: 'login'})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const setToken = ({ commit, dispatch }, token) => {

  if (_.isNil(token)) {
    return dispatch('checkTokenExists').then((token) => {
      setHttpToken(token)
    })
  }

  commit(types.SET_TOKEN, token)
  setHttpToken(token)
}

export const checkTokenExists = ({ commit, dispatch }, token) => {
  return storage.get('authToken').then((token) => {
    if (_.isEmpty(token)) {
      return Promise.reject(new Error('NO_STORAGE_TOKEN'))
    }
    return Promise.resolve(token)
  })
}

export const checkTokenExistsApi = ({ commit, dispatch }) => {
  let slug = ''
  return api.fetchUser(slug).then((response) => {
    // Nothing
  }).catch((error) => {
    dispatch('logout')
  })
}

export const fetchUser = ({ commit, dispatch }, { context }) => {
  let slug = store.getters['global/subdomain']
  /* if (ENV_IS_PRODUCTION) {
    slug = location.hostname.split('.').shift()
  } else {
    let url = new URL(location)
    let slugParam = url.searchParams.get('slug')

    if (_.isEmpty(slugParam)) {
      slug = ''
    } else if (slugParam === 'my') {
      slug = ''
    } else {
      slug = slugParam
    }
  }
  if (_.isNil(slug)) {
    slug = ''
  } */
  commit(types.SET_IS_COMPANY_SUBDOMAIN, slug)
  return api.fetchUser(slug).then((response) => {
    context.slug = ''
    let success = false
    if (_.get(response, 'data.access_token')) {
      success = true
      dispatch('setToken', response.data.access_token)
    } else if (!success && _.get(response, 'headers.bearer')) {
      success = true
      dispatch('setToken', response.headers.bearer)
    }
    if (!success) {
      return success
    }
    commit(types.SET_AUTHENTICATED, true)
    commit(types.SET_USER_DATA, response.data.data)
    commit(types.SET_USER_FORM_DATA, response.data.data)

    // Get badges to display collected badges info near profile
    // dispatch('badges/getBadges')

    // dispatch('getNotifications', { context: this })

    // Change client language to user's defined
    if (!_.isEmpty(response.data) && !_.isEmpty(response.data.data) && response.data.data.lang) {
      i18next.changeLanguage(response.data.data.lang)
    }

    // Redirect to the first company
    let companies = response.data.data.companies
    if (!_.isEmpty(companies)) {
      commit(types.SET_USER_ROLE, companies[0].role)
      if (!_.isUndefined(companies[0].company_transaction_points)) {
        commit(types.SET_USER_COMPANY_POINTS, companies[0].company_transaction_points)
      }
      commit(types.SET_USER_DEFAULT_COMPANY, companies[0])

      context.slug = companies[0].slug
      slug = context.slug

      // If user is company's owner, redirect to company dashboard
      if (companies[0].plan === 'pro' && companies[0].role === 'owner') {
        store.commit('global/SET_AFTER_LOGIN_PAGE', 'dashboard.company.home')
      } else {
        store.commit('global/SET_AFTER_LOGIN_PAGE', companies[0].plan)
      }

      // store.commit('global/SET_AFTER_LOGIN_PAGE', 'choose.company')

      // if (!_.isNil(slug)) {
      //   dispatch('fetchCompany', { payload: slug })
      // }

      dispatch('fetchCurrentCompany') // Grabs company slug from global getter

      if (companies[0].slug === 'my') {
        // Do not redirect and do not fetch company
        context.slug = ''
      } else {
        // Redirect to subdomain
        // window.location = `http://${companies[0].slug}.localhost:8080`
      }
    } else {
      store.commit('global/SET_AFTER_LOGIN_PAGE', 'public')
    }
  }).catch((error) => {
    dispatch('logout')
  })
}

export const clearAuth = ({ commit }, token) => {
  commit(types.SET_AUTHENTICATED, false)
  commit(types.SET_USER_DATA, null)
  commit(types.SET_TOKEN, null)
  setHttpToken(null)
}

export const updateProfile = ({ dispatch, commit }, { context, payload }) => {
  api.updateProfile(payload).then((response) => {
    context.success = true
    context.init()
    setTimeout(function () {
      commit(types.SET_BASIC_USER_DATA, response.data.data)
    }, 2000)
    // location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateUserLang = ({ commit }, { payload }) => {
  api.updateUserLang(payload).then((response) => {
    // Nothing
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const switchCompany = ({ commit, dispatch }, { context, payload }) => {
  // Redirect to companies subdomain
  // Send company's slug as param to /me
  if (ENV_IS_PRODUCTION) {
    window.location = `https://${payload}.${IDB_DOMAIN}`
  } else {
    window.location = `https://${location.hostname}:${location.port}/login?slug=${payload}`
  }
}

export const fetchCompany = ({ commit }, { payload }) => {
  if (!_.isUndefined(payload)) {
    api.fetchCompany(payload).then((response) => {
      commit(types.SET_COMPANY, response.data.data)
    })
  }
}

export const fetchCurrentCompany = ({ commit }) => {
  let slug = store.getters['global/subdomain']
  if (!_.isUndefined(slug)) {
    api.fetchCompany(slug).then((response) => {
      commit(types.SET_COMPANY, response.data.data)
    })
  }
}

export const fetchCompanyOnLogin = ({ commit }, { payload, context }) => {
  if (!_.isUndefined(payload)) {
    commit(types.SET_IS_COMPANY_LOGIN_LOADING, true)
    commit(types.SET_IS_COMPANY_SUBDOMAIN, payload)
    api.fetchCompanyOnLogin(payload).then((response) => {
      commit(types.SET_COMPANY_LOGIN, response.data.data)
      commit(types.SET_IS_COMPANY_LOGIN_LOADING, false)
    }).catch((error) => {
      commit(types.SET_IS_COMPANY_LOGIN_LOADING, false)
    })
  } else {
    commit(types.SET_IS_COMPANY_LOGIN_LOADING, false)
  }
}

export const deleteSelf = ({ commit }, { context }) => {
  api.deleteSelf().then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserDefaultCard = ({ commit }, { context }) => {
  api.getUserDefaultCard().then((response) => {
    commit(types.SET_USER_DEFAULT_CARD, response.data.data)
    if (!_.isEmpty(response.data.data)) {
      context.paymentCard = 'default'
    }
  }).catch((error) => {
    commit(types.SET_USER_DEFAULT_CARD, {})
    // context.errors = error.response.data.errors
  })
}

export const completeDeleteSelf = ({ dispatch }, { context, payload }) => {
  api.completeDeleteSelf(payload).then((response) => {
    context.success = true
    dispatch('clearAuth')
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getCompanyTeams = ({ commit }, { context }) => {
  api.getCompanyTeams().then((response) => {
    commit(types.SET_COMPANY_TEAMS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getCompanyTeamEmployees = ({ commit }, { context, payload }) => {
  api.getCompanyTeamEmployees(payload).then((response) => {
    commit(types.SET_COMPANY_TEAM_EMPLOYEES_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateCurrentTeam = ({ commit, dispatch }, { context, payload }) => {
  api.updateCurrentTeam(payload).then((response) => {
    context.success = true
    location.reload()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getCompanyTeamResults = ({ commit }, { context, payload }) => {
  api.getCompanyTeamResults(payload).then((response) => {
    commit(types.SET_COMPANY_TEAM_RESULTS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const sendNotificationsToken = ({ commit }, { context, payload }) => {
  api.sendNotificationsToken(payload).then((response) => {
    // Done!
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getNotifications = ({ commit, dispatch }, { context }) => {
  api.getNotifications().then((response) => {
    commit(types.SET_USER_NOTIFICATIONS_DATA, response.data)
    commit(types.SET_USER_NOTIFICATIONS_AS_READ)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getNotificationsSettings = ({ commit }, { context }) => {
  context.isLoadingNotifications = true
  api.getNotificationsSettings().then((response) => {
    commit(types.SET_NOTIFICATIONS_SETTINGS_DATA, response.data)
    context.isLoadingNotifications = false
  }).catch((error) => {
    context.isLoadingNotifications = false
    context.errorsNotifications = error.response.data.errors
  })
}

export const changeNotificationSettings = ({ dispatch }, { context, payload }) => {
  context.successNotifications = false
  context.errorsNotifications = []
  api.changeNotificationSettings(payload).then((response) => {
    // dispatch('getNotificationsSettings', { context: context })
    context.successNotifications = true
  }).catch((error) => {
    context.errorsNotifications = error.response.data.errors
  })
}

export const getDailyTasks = ({ commit }, { context }) => {
  context.isLoading = true
  api.getDailyTasks().then((response) => {
    commit(types.SET_DAILY_TASKS, response.data)
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

// Ping API to collect platform usage time
export const ping = ({ commit }, { context, payload }) => {
  api.ping(payload).then((response) => {
    context.apiError = false
  }).catch((error) => {
    if (!_.isUndefined(error.response) && !_.isEmpty(error.response)) {
      context.apiError = false
      if (!_.isUndefined(error.response.data) && !_.isEmpty(error.response.data)) {
        context.apiError = false
      } else {
        context.apiError = true
      }
    } else {
      context.apiError = true
    }
  })
}
