const MissingProvider = () => import(/* webpackChunkName: "Modals" */ '../components/MissingProvider')
const ConnectDevice = () => import(/* webpackChunkName: "Modals" */ '../components/ConnectDevice')

export default [
  {
    path: '#missingProvider',
    name: 'missingProvider',
    component: MissingProvider
  },
  {
    path: '/dashboard/#:name',
    name: 'connectDevice',
    component: ConnectDevice
  }
]
