import * as api from '../api'
import * as types from './mutation-types'
import { defaultData } from './state'

export const search = ({ commit, state }, data) => {
  commit(types.SET_LOADING, true)
  commit(types.SET_ENDPOINT, data.endpoint)

  return api.search(data.endpoint, data.request).then((response) => {
    commit(types.SET_DATA, { response: response.data, error: null })
    commit(types.SET_LOADING, false)
  }).catch((errors) => {
    commit(types.SET_DATA, { data: defaultData, error: errors })
    commit(types.SET_LOADING, false)
  })
}

export const getFiltersData = ({ commit }, filters) => {
  commit(types.SET_LOADING, true)
  const promises = []
  const data = {}
  filters.forEach((filter, key) => {
    if (typeof filter.getData !== 'string') {
      data[filter.name] = filter.getData
      delete filters[key]
    } else {
      promises.push(api.getFiltersData(filter.getData))
      commit(types.SET_LOADING, false)
    }
  })
  const responses = {}
  Promise.all(promises).then((response) => {
    response.forEach((promise, key) => {
      const endpoint = promise.config.url
        .replace(`${process.env.API_URL}/v1/`, '')
      responses[endpoint] = promise.data.data
    })
  }).then(() => {
    filters.forEach((filter, key) => {
      if (responses.hasOwnProperty(filter.getData)) {
        data[filter.name] = filter.formatGetData(responses[filter.getData])
      }
    })
    commit(types.SET_FILTERS_DATA, { data, error: null })
    commit(types.SET_LOADING, false)
  }).catch(errors => {
    commit(types.SET_FILTERS_DATA, { data: null, error: errors })
    commit(types.SET_LOADING, false)
  })
}

export const deleteItem = ({ dispatch }, { context, payload }) => {
  api.deleteItem(payload).then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateItem = ({ dispatch }, { context, payload }) => {
  api.updateItem(payload).then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}
