<template>
  <div class="preloader">
    <svg class="spinner" width="32px" height="32px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="4" stroke-linecap="round" cx="16" cy="16" r="14"></circle>
    </svg>
    <div v-if="1 === 2" class="title text-center mt-3">
      <span v-if="title">{{ title }}</span>
      <span v-else>{{ $t('misc.preloader.default') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'preloader',
  props: {
    title: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>

  .preloader {
    display: block;
    align-items: center;
    justify-content: center;
  }

  .title {
    display: block;
    margin: 0 auto;
    font-size: 1.2rem;
  }

  $offset: 187;
  $duration: 1.4s;

  .spinner {
    display: block;
    margin: 0 auto;
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
      dash $duration ease-in-out infinite,
      colors ($duration*4) ease-in-out infinite;
  }

  @keyframes colors {
    0% { stroke: $brand-danger; }
    25% { stroke: $brand-danger; }
    50% { stroke: $brand-danger; }
    75% { stroke: $brand-primary; }
    100% { stroke: $brand-primary; }
  }

  @keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
      stroke-dashoffset: $offset/4;
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform:rotate(450deg);
    }
  }
</style>
