import axios from 'axios'

export const search = (endpoint, request) => {
  return axios.get(`${process.env.API_URL}/v1/${endpoint}?${request}`)
}

export const getFiltersData = (endpoint) => {
  return axios.get(`${process.env.API_URL}/v1/${endpoint}`)
}

export const deleteItem = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/${payload.endpoint}/${payload.id}`)
}

export const updateItem = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/${payload.endpoint}`, payload)
}
