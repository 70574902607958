export const authenticated = (state) => {
  if (!_.isEmpty(state.user)) {
    return state.user.authenticated
  }
  return false
}

export const user = (state) => {
  const data = state.user.data
  return data
}

export const userForm = (state) => {
  return state.userForm
}

export const role = (state) => {
  if (!_.isEmpty(state.user)) {
    if (!_.isEmpty(state.user.data)) {
      return state.user.data.role
    }
  }
  return null
}

export const defaultCard = (state) => {
  return state.defaultCard
}

export const defaultCompany = (state) => {
  if (!_.isEmpty(state.user)) {
    if (!_.isEmpty(state.user.data)) {
      return state.user.data.defaultCompany
    }
  }
  return null
}

export const currentCompany = (state) => {
  if (!_.isEmpty(state.user)) {
    if (!_.isEmpty(state.user.data)) {
      return state.user.data.currentCompany
    }
  }
  return null
}

export const companyLimits = (state) => {
  return state.companyLimits
}

export const companyLogin = (state) => {
  return state.companyLogin
}

export const isCompanyLoginLoading = (state) => {
  return state.isCompanyLoginLoading
}

export const isCompanySubdomain = (state) => {
  return state.isCompanySubdomain
}

export const companyTeams = (state) => {
  if (!_.isEmpty(state.companyTeams)) {
    return state.companyTeams.data
  }
  return []
}

export const companyTeamEmployees = (state) => {
  if (!_.isEmpty(state.companyTeamEmployees)) {
    return state.companyTeamEmployees.data
  }
  return {}
}

export const companyTeamResults = (state) => {
  if (!_.isEmpty(state.companyTeamResults)) {
    return state.companyTeamResults.data
  }
  return {}
}

export const userNotifications = (state) => {
  const data = state.userNotifications.data
  return data
}

export const notificationsSettings = (state) => {
  const data = state.notificationsSettings.data
  return data
}

export const dailyTasks = (state) => {
  return state.dailyTasks
}

export const showUnlockedFeature = (state) => {
  return state.showUnlockedFeature
}

export const justUnlockedFeature = (state) => {
  return state.justUnlockedFeature
}
