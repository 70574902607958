export default {
  loading: true,
  allChallenges: {},
  singleChallenge: {},
  prizesCategories: {},
  prizesPartners: {},
  prizesItems: {},
  lotteryWinners: {},
  allLotteries: {},
  singleLottery: {},
  singleChallengeDays: [],
  singleChallengeDay: {},
  singleChallengeTasks: [],
  singleChallengeTask: {},
  isDayLoading: false,
  isTasksLoading: false
}
