import * as api from '../api/index'
import * as types from './mutation-types'
import router from '@/router'
import store from '@/store'

export const getAllChallengesData = ({ commit }, { context }) => {
  api.getAllChallengesData().then((response) => {
    commit(types.SET_ALL_CHALLENGES_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeData = ({ commit }, { context, payload }) => {
  commit(types.SET_LOADING, true)
  api.getSingleChallengeData(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_DATA, response.data)
    commit(types.SET_LOADING, false)
  }).catch((error) => {
    context.errors = error.response.data.errors
    commit(types.SET_LOADING, false)
  })
}

export const getPrizesCategoriesData = ({ commit }, { context }) => {
  api.getPrizesCategoriesData().then((response) => {
    commit(types.SET_PRIZES_CATEGORIES_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getPrizesPartnersData = ({ commit }, { context, payload }) => {
  api.getPrizesPartnersData(payload).then((response) => {
    commit(types.SET_PRIZES_PARTNERS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getPrizesItemsData = ({ commit }, { context, payload }) => {
  api.getPrizesItemsData(payload).then((response) => {
    commit(types.SET_PRIZES_ITEMS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const createNewChallenge = ({ dispatch }, { context, payload }) => {
  api.createNewChallenge(payload).then((response) => {
    let challengeId = response.data.data.id
    // dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    router.push({name: 'mini.company.challenges.single.edit', params: { challengeId: challengeId }})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateChallenge = ({ dispatch }, { context, payload }) => {
  api.updateChallenge(payload).then((response) => {
    // let challengeId = payload.challenge_id
    // dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    context.challengeUpdated = true
    if (payload.draft) {
      context.successPublish = false
      context.successDraft = true
    } else {
      context.successPublish = true
      context.successDraft = false
    }
  }).catch((error) => {
    context.successPublish = false
    context.successDraft = false
    context.errors = error.response.data.errors
  })
}

export const addPrize = ({ dispatch }, { context, payload }) => {
  api.addPrize(payload).then((response) => {
    dispatch('getPrizesItemsData', { context: this, payload: payload.challenge_id })
    context.addedPrize = false
    context.newPrize = {}
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updatePrize = ({ dispatch }, { context, payload }) => {
  api.updatePrize(payload).then((response) => {
    dispatch('getPrizesItemsData', { context: this, payload: payload.challenge_id })
    context.successPartners = false
    context.successPrizes = true
  }).catch((error) => {
    context.successPartners = false
    context.successPrizes = true
    context.errors = error.response.data.errors
  })
}

export const addPartner = ({ dispatch }, { context, payload }) => {
  api.addPartner(payload).then((response) => {
    dispatch('getPrizesPartnersData', { context: this, payload: payload.challenge_id })
    context.addedPartner = false
    context.newPartner = {}
    context.successPartners = true
    context.successPrizes = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.successPartners = false
    context.successPrizes = false
  })
}

export const updatePartner = ({ dispatch }, { context, payload }) => {
  api.updatePartner(payload).then((response) => {
    dispatch('getPrizesPartnersData', { context: this, payload: payload.challenge_id })
    context.successPartners = true
    context.successPrizes = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.successPartners = false
    context.successPrizes = false
  })
}

export const changePrizesOrder = ({ dispatch }, { context, payload }) => {
  api.changePrizesOrder(payload).then((response) => {
    context.unsavedPrizesOrder = false
    // sispatch('getPrizesItemsData', { context: this, payload: payload.challenge_id })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deleteChallenge = ({ dispatch }, { context, payload }) => {
  api.deleteChallenge(payload).then((response) => {
    router.push({name: 'dashboard.company.challenges'})
    dispatch('getAllChallengesData', { context: this })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const startLotteryStep1 = ({ commit }, { context, payload }) => {
  api.startLotteryStep1(payload).then((response) => {
    let challengeId = response.data.challenge_id
    let lotteryId = response.data.lottery_id
    // commit(types.SET_LOTTERY_WINNERS_DATA, response.data)
    router.push({name: 'mini.company.challenges.lottery', params: { challengeId: challengeId, lotteryId: lotteryId }})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const startLotteryStep2 = ({ commit }, { context, payload }) => {
  api.startLotteryStep2(payload).then((response) => {
    let challengeId = response.data.challenge_id
    let lotteryId = response.data.lottery_id
    commit(types.SET_SINGLE_LOTTERY_DATA, response.data)
    // commit(types.SET_LOTTERY_WINNERS_DATA, response.data)
    if (_.isEmpty(payload.declined_winners)) {
      router.push({name: 'mini.company.challenges.single.edit', params: { challengeId: challengeId, tab: 4 }})
    } else {
      router.push({name: 'mini.company.challenges.single.edit', params: { challengeId: challengeId, tab: 4 }})
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const startLotteryStep3 = ({ commit }, { context, payload }) => {
  api.startLotteryStep3(payload).then((response) => {
    let challengeId = response.data.challenge_id
    let lotteryId = response.data.lottery_id
    commit(types.SET_SINGLE_LOTTERY_DATA, response.data)
    // commit(types.SET_LOTTERY_WINNERS_DATA, response.data)
    router.push({name: 'mini.company.challenges.single.edit', params: { challengeId: challengeId, tab: 4 }})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const cancelLottery = ({ commit }, { context, payload }) => {
  api.cancelLottery(payload).then((response) => {
    let challengeId = payload.challenge_id
    router.push({name: 'mini.company.challenges.single.edit', params: { challengeId: challengeId, tab: 4 }})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getAllLotteriesData = ({ commit }, { context, payload }) => {
  api.getAllLotteriesData(payload).then((response) => {
    commit(types.SET_ALL_LOTTERIES_DATA, response.data)
    context.errors = {}
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleLotteryData = ({ commit }, { context, payload }) => {
  api.getSingleLotteryData(payload).then((response) => {
    commit(types.SET_SINGLE_LOTTERY_DATA, response.data)
    context.errors = {}
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deleteAchievement = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  api.deleteAchievement(payload).then((response) => {
    context.success = true
    context.isLoading = false
    let payload2 = {
      challengeId: payload.challengeId,
      perPage: payload.perPage,
      offset: payload.offset,
      task: payload.task,
      team: payload.team,
      score_id: payload.score_id
    }
    store.dispatch('miniAchievements/getAllAchievementsData', { context: this, payload: payload2 })
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeDays = ({ commit }, { context, payload }) => {
  api.getSingleChallengeDays(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_DAYS, response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeDay = ({ commit, dispatch }, { context, payload }) => {
  context.isLoading = true
  commit(types.SET_DAY_LOADING, true)
  api.getSingleChallengeDay(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_DAY, response.data.data)
    // To compare original data with modified, for unsaved changes warning. Clone object by removing object's reactivity.
    context.dayDataOriginal = _.cloneDeep(response.data.data)
    context.isLoading = false
    dispatch('getSingleChallengeLevels', { payload: payload } )
  }).catch((error) => {
    context.isLoading = false
    commit(types.SET_DAY_LOADING, false)
    context.errors = error.response.data.errors
  })
}

export const getSingleChallengeTasks = ({ commit }, { context, payload }) => {
  api.getSingleChallengeTasks(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_TASKS, response.data.data)
    commit(types.SET_TASKS_LOADING, false)
    commit(types.SET_DAY_LOADING, false)
    context.tasksEditable = response.data.data
  }).catch((error) => {
    commit(types.SET_TASKS_LOADING, false)
    context.errors = error.response.data.errors
  })
}

export const createNewChallengeTask = ({ dispatch }, { context, payload }) => {
  context.errors = []
  api.createNewChallengeTask(payload).then((response) => {
    dispatch('getSingleChallengeTasks', { payload: payload, context: context } )
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateChallengeDay = ({ dispatch, commit }, { context, payload }) => {
  context.errors = []
  context.isLoading = true
  api.updateChallengeDay(payload).then((response) => {
    commit(types.SET_SINGLE_CHALLENGE_DAY, payload)
    // To compare original data with modified, for unsaved changes warning. Clone object by removing object's reactivity.
    context.dayDataOriginal = _.cloneDeep(payload)
    // dispatch('getSingleChallengeDay', { context: context, payload: { id: payload.id } })
    context.success = true
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const updateChallengeDaysOrder = ({ dispatch }, { context, payload }) => {
  context.errors = []
  api.updateChallengeDaysOrder(payload).then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateChallengeTask = ({ dispatch }, { context, payload }) => {
  context.errors = []
  context.isLoading = false
  api.updateChallengeTask(payload).then((response) => {
    // To compare original data with modified, for unsaved changes warning. Clone object by removing object's reactivity.
    delete payload.task_id // To not mess with original and already existing task data
    delete payload.day_id // To not mess with original and already existing task data
    context.taskDataOriginal = _.cloneDeep(payload)
    context.success = true
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const deleteChallengeTask = ({ dispatch }, { context, payload }) => {
  context.errors = []
  api.deleteChallengeTask(payload).then((response) => {
    return true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateChallengeTasksOrder = ({ dispatch }, { context, payload }) => {
  context.errors = []
  api.updateChallengeTasksOrder(payload).then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}
