export const providers = (state) => {
  return state.providers
}

export const currentProviders = (state) => {
  return state.currentProviders
}

export const response = (state) => {
  return state.providerResponse
}

export const showUnlockedLevels = (state) => {
  return state.showUnlockedLevels
}

export const showUnlockedBadges = (state) => {
  return state.showUnlockedBadges
}
