<template>
  <div>
    <b-alert v-if="!_.isEmpty(msgComp) && !_.isUndefined(msgComp.text)"
             variant="success"
             show>
      <spacer color="green"></spacer>
      <i class="material-icons" v-if="!_.isUndefined(msgComp.icon)">{{ msgComp.icon }}</i>
      <div v-html="msgText" class="align-center" :class="{ 'd-inline-block': msgComp.icon }"></div>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'response-success',
  props: {
    msg: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    msgComp () {
      if (!_.isUndefined(this.msg)) {
        return this.msg
      }
      return {}
    },
    msgText () {
      if (!_.isEmpty(this.msgComp) && !_.isUndefined(this.msgComp.text)) {
        if (!_.isUndefined(this.msgComp.params) && !_.isEmpty(this.msgComp.params)) {
          return this.$i18n.t(this.msgComp.text. this.msgComp.params)
        }
        return this.$i18n.t(this.msgComp.text)
      }
      return ''
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
  .green {
    background-color: $form-valid-color;
  }
  .spacer {
    // @extend .my-4;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
  }

  i {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
</style>
