export const datatable = (state) => {
  return state.response
}

export const filters = (state) => {
  return state.filters
}

export const isLoading = (state) => {
  return state.loading
}

// Company or public (my) subdomain
export const subdomain = (state) => {
  return state.subdomain
}

// Should user see "public" or "company" content
export const content = (state) => {
  return state.content
}

export const afterLoginPage = (state) => {
  return state.afterLoginPage
}
