export const isLoading = state => state.loading

export const allChallenges = (state) => {
  const data = state.allChallenges.data
  return data
}

export const singleChallenge = (state) => {
  const data = state.singleChallenge.data
  return data
}

export const prizesCategories = (state) => {
  const data = state.prizesCategories.data
  return data
}

export const prizesPartners = (state) => {
  const data = state.prizesPartners.data
  return data
}

export const prizesItems = (state) => {
  const data = state.prizesItems.data
  return data
}

export const lotteryWinners = (state) => {
  const data = state.lotteryWinners
  return data
}

export const allLotteries = (state) => {
  const data = state.allLotteries.data
  return data
}

export const singleLottery = (state) => {
  const data = state.singleLottery.data
  return data
}

export const singleChallengeDays = (state) => {
  return state.singleChallengeDays
}

export const singleChallengeDay = (state) => {
  return state.singleChallengeDay
}

export const singleChallengeTasks = (state) => {
  return state.singleChallengeTasks
}

export const singleChallengeTask = (state) => {
  return state.singleChallengeTask
}

export const isDayLoading = (state) => {
  return state.isDayLoading
}

export const isTasksLoading = (state) => {
  return state.isTasksLoading
}
