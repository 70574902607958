import state from './state'
import * as api from '../api'

export const conversationSeed = ({ rootState }) => {
  // let convoRef = DB.collection('conversations')
  //
  // convoRef.add({
  //   created: Date.now(),
  //   users: ['mr_a', 'mr_b'],
  //   messages: [
  //     { id: uuidv4(), text: 'Hi there', sender: 'mr_a', created: Date.now() },
  //     { id: uuidv4(), text: 'Hi to you too!', sender: 'mr_b', created: Date.now() }
  //   ]
  // })
  //
  // convoRef.add({
  //   created: Date.now(),
  //   users: ['mr_a', 'mr_c'],
  //   messages: []
  // })
}

export const conversationGet = ({ commit, rootState }, { professionalId }) => {
  // let convoRef = DB.collection('conversations')
  // convoRef.get().then(function(result) {
  //   result.forEach(function(doc) {
  //     let conversation = doc
  //     commit('SET_CONVERSATION', { conversation })
  //   })
  // })
}

export const sendMessage = ({ commit, dispatch, getters }, { context, payload }) => {
  let inputDisabled = getters.inputDisabled
  if (!inputDisabled) {
    commit('SET_INPUT_DISABLED', true)
    api.sendMessage(payload).then((response) => {
      // context.newMessageText = ''
      // context.inputDisabled = false
      // context.success = true
      commit('SET_SPECIFIC_MESSAGES', response.data.data)
      setTimeout(function () {
        if (!_.isUndefined(payload.conversation_id)) {
          dispatch('getMessages', {payload: payload, context: context})
        }
        dispatch('getConversations', {context: context})
      }, 2000)
      commit('SET_INPUT_DISABLED', false)
    }).catch((error) => {
      commit('SET_INPUT_DISABLED', false)
      context.errors = error.response.data.errors
    })
  } else {
    // Message already sent
  }
}

export const getConversations = ({ commit, dispatch }, { context }) => {
  let demo = {
    data: [
      {
        conversation_id: '1572993110401',
        user_ids: [
          6
        ],
        user_first_names: [
          'efectio'
        ],
        user_last_names: [
          ''
        ],
        user_images: [
          'https://my.efectio.com/static/favicon.ico'
        ],
        last_message_snippet: 'Ielūgums uz...',
        updated: '2019-11-29T12:26:30+00:00',
        unread: true
      },
      {
        conversation_id: '1573041197359',
        user_ids: [
          2269
        ],
        user_first_names: [
          'Jānis',
          'Maija'
        ],
        user_last_names: [
          'Bērziņš',
          'Ziemele'
        ],
        user_images: [
          'https://s3.eu-north-1.amazonaws.com/finefit/users/2269/9dbb60a0-9cb4-11e9-a54a-bf3b2387b6d2.jpg',
          ''
        ],
        last_message_snippet: 'Rīt būsi uz vietas?',
        updated: '2019-11-29T14:15:28+00:00',
        unread: true
      },
      {
        conversation_id: '1573773044743',
        user_ids: [
          2301
        ],
        user_first_names: [
          'Aija'
        ],
        user_last_names: [
          'Sauja'
        ],
        user_images: [
          ''
        ],
        last_message_snippet: 'Hey!',
        updated: '2019-11-29T12:25:57+00:00',
        unread: false
      },
      {
        conversation_id: '1574244145033',
        user_ids: [
          2308
        ],
        user_first_names: [
          'Emīls'
        ],
        user_last_names: [
          'Šalle'
        ],
        user_images: [
          ''
        ],
        last_message_snippet: 'Paskaties šo izaicinājumu, liekas...',
        updated: '2019-11-29T12:25:40+00:00',
        unread: false
      },
      {
        conversation_id: '1575036853276',
        user_ids: [
          2306
        ],
        user_first_names: [
          'Andrejs',
          'Edmunds',
          'Mārtiņš'
        ],
        user_last_names: [
          'Sundars',
          'Lietiņš',
          'Sniedziņš'
        ],
        user_images: [
          '',
          '',
          ''
        ],
        last_message_snippet: 'Testing unread messages notif...',
        unread: false
      },
      {
        conversation_id: '1573728133074',
        user_ids: [
          2278
        ],
        user_first_names: [
          'Antons'
        ],
        user_last_names: [
          'Krancis'
        ],
        user_images: [
          ''
        ],
        last_message_snippet: 'Prieks sadarboties!',
        updated: '2019-11-20T11:27:11+00:00',
        unread: false
      }
    ]
  }
  commit('SET_CONVERSATIONS', demo.data)
  // api.getConversations().then((response) => {
  //   let conversations = response.data.data
  //   commit('SET_CONVERSATIONS', conversations)
  // }).catch((error) => {
  //   context.errors = error.response.data.errors
  // })
}

export const getMessages = ({ commit, dispatch }, { context, payload }) => {
  if (!_.isUndefined(payload.no_loading) && payload.no_loading) {
    // No Preloader
  } else {
    commit('SET_LOADING', true)
  }
  let demo = {
    data: [
      {
        me: false,
        user_id: 6,
        user_first_name: 'efectio',
        user_last_name: '',
        user_image: 'https://my.efectio.com/static/favicon.ico',
        message: 'Labdien, Pēteri!',
        type: 'text',
        edited: false,
        updated: '',
        created: '2019-11-05T22:31:50+00:00',
        unread: false
      },
      {
        me: true,
        user_id: 2,
        user_first_name: 'Peteris',
        user_last_name: 'Kelle',
        user_image: 'https://s3.eu-north-1.amazonaws.com/finefit/users/9/7f994970-91d3-11e9-b78c-8b3d277e7fc9.jpg',
        message: 'Labdien!',
        type: 'text',
        edited: false,
        updated: '2019-11-14T10:44:37+00:00',
        created: '2019-11-14T10:39:30+00:00',
        unread: false
      },
      {
        me: false,
        user_id: 6,
        user_first_name: 'efectio',
        user_last_name: '',
        user_image: 'https://my.efectio.com/static/favicon.ico',
        message: 'Ielūdzam tevi uz Ziemassvētku balli!',
        type: 'text',
        edited: false,
        updated: '2019-11-14T10:46:00+00:00',
        created: '2019-11-14T10:45:35+00:00',
        unread: false
      },
      {
        me: true,
        user_id: 2,
        user_first_name: 'Peteris',
        user_last_name: 'Kelle',
        user_image: 'https://s3.eu-north-1.amazonaws.com/finefit/users/9/7f994970-91d3-11e9-b78c-8b3d277e7fc9.jpg',
        message: 'Paldies!',
        type: 'text',
        edited: false,
        updated: '2019-11-15T09:49:45+00:00',
        created: '2019-11-14T10:46:20+00:00',
        unread: false
      },
      {
        me: true,
        user_id: 2,
        user_first_name: 'Peteris',
        user_last_name: 'Kelle',
        user_image: 'https://s3.eu-north-1.amazonaws.com/finefit/users/9/7f994970-91d3-11e9-b78c-8b3d277e7fc9.jpg',
        message: '😄',
        type: 'emoji',
        edited: false,
        updated: '2019-11-15T09:49:45+00:00',
        created: '2019-11-14T21:37:36+00:00',
        unread: false
      },
      {
        me: false,
        user_id: 6,
        user_first_name: 'efectio',
        user_last_name: '',
        user_image: '',
        message: 'User efectio has left this conversation.',
        type: 'system',
        edited: false,
        updated: '2019-11-15T09:50:47+00:00',
        created: '2019-11-15T09:49:51+00:00',
        unread: false
      }
    ]
  }
  commit('SET_MESSAGES', demo.data)
  commit('SET_LOADING', false)
  // api.getMessages(payload.conversation_id).then((response) => {
  //   console.log('Conversation messages', response)
  //   commit('SET_MESSAGES', response.data.data)
  //   commit('SET_LOADING', false)
  //   setTimeout(function () {
  //     dispatch('getConversations', { context: context })
  //   }, 2000)
  // }).catch((error) => {
  //   commit('SET_LOADING', false)
  //   context.errors = error.response.data.errors
  // })
}

export const userSeed = ({ rootState }) => {
  // let userRef = DB.collection('users')
  //
  // userRef.doc('mr_a').set({
  //   firstName: 'Andy',
  //   lastName: 'Andyson'
  // })
  //
  // userRef.doc('mr_b').set({
  //   firstName: 'Ben',
  //   lastName: 'Benson'
  // })
  //
  // userRef.doc('mr_c').set({
  //   firstName: 'Cee',
  //   lastName: 'Ceeson'
  // })
}

export const userGet = ({ commit, rootState }) => {
  // let userRef = DB.collection('users')
  //
  // userRef.get().then(function(result) {
  //   result.forEach(function(doc) {
  //     let user = doc
  //     commit('SET_USER', { user })
  //   })
  // })
}

export const getUserConversationMessages = ({ commit, rootState }) => {}
export const saveMessagesAsRead = ({ commit, rootState }) => {}
