import axios from 'axios'

export const getBadges = (payload) => {
  let publicFilter = '?filter[public]=false'
  if (!_.isUndefined(payload.type)) {
    if (payload.type === 'company') {
      publicFilter = `?filter[public]=false`
    } else if (payload.type === 'public') {
      publicFilter = `?filter[public]=true`
    }
  }

  if (!_.isUndefined(payload.public)) {
    publicFilter = `?filter[public]=${payload.public}`
  }

  let perPage = payload.perPage
  let offset = payload.offset

  return axios.get(`${process.env.API_URL}/v1/badges/${publicFilter}&limit=${perPage}&offset=${offset}`)
}

export const checkUnlockedBadges = () => {
  return axios.post(`${process.env.API_URL}/v1/badges/checkBadges`)
}
