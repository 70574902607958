import axios from 'axios'

export const getStatsData = () => {
  return axios.get(`${process.env.API_URL}/v1/stats/overall`)
}

export const getAllStatsData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/stats/all/${payload.id}`)
}

export const getFilteredStatsData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/stats/filtered`, {
    params: {
      id: payload.id,
      start: payload.start,
      finish: payload.finish,
      activity: payload.activity,
      amount_from: payload.amountFrom,
      amount_to: payload.amountTo,
      target: payload.target,
      result: payload.result,
      show_data_in: payload.showDataIn,
      limit: payload.perPage,
      offset: payload.offset
    }
  })
}

export const getMood = () => {
  return axios.get(`${process.env.API_URL}/v1/mood`)
}

export const postMood = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mood`, payload)
}

export const getAllTransactionsData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/transactions`, {
    params: {
      'filter[start]': payload.start,
      'filter[finish]': payload.finish,
      'filter[public]': payload.public,
      limit: payload.perPage,
      offset: payload.offset
    }
  })
}

export const getTransactionsBalanceData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/transactions/balance`, {
    params: {
      'filter[start]': payload.start,
      'filter[finish]': payload.finish,
      'filter[public]': payload.public,
      limit: payload.perPage,
      offset: payload.offset
    }
  })
}

export const getHealthscoreData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/healthscore`, payload)
}

export const getScores = (payload) => {
  let filterPublic = ''
  if (!_.isNil(payload.public)) {
    filterPublic = `filter[public]=${payload.public}`
  }
  return axios.get(`${process.env.API_URL}/v1/scores?${filterPublic}`, payload)
}
