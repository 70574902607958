import * as types from './mutation-types'

export default {
  [types.SET_PROVIDERS_DATA] (state, data) {
    state.providers = data
  },
  [types.SET_CURRENT_PROVIDERS] (state, data) {
    state.currentProviders = data
  },
  [types.SET_SHOW_UNLOCKED_LEVELS] (state, data) {
    state.showUnlockedLevels = data
  },
  [types.SET_SHOW_UNLOCKED_BADGES] (state, data) {
    state.showUnlockedBadges = data
  }
}
