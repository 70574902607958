const Mini = () => import(/* webpackChunkName: "Home" */ '../components/Mini')
import MiniPages from '@/app/mini-pages/routes'

export default [
  {
    path: '/quests',
    component: Mini,
    name: 'mini',
    props: true,
    children: MiniPages,
    meta: { needsAuth: true }
  }
]
