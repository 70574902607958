import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

import state from './state'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

import auth from '@/app/auth/store'
import authSteps from '@/app/auth-steps/store'
import mini from '@/app/mini/store'
import miniPages from '@/app/mini-pages/store'
import miniChallenges from '@/app/mini-challenges/store'
import miniAchievements from '@/app/mini-achievements/store'
import miniAdminEmployees from '@/app/mini-admin-employees/store'
import miniAdminPages from '@/app/mini-admin-pages/store'
import global from '@/app/global/store'
import modals from '@/app/modals/store'
import statistics from '@/app/statistics/store'
import adminMiniChallenges from '@/app/mini-admin-challenges/store'
import messenger from '@/app/global/components/messenger/store'
import badges from '@/app/badges/store'

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    auth,
    authSteps,
    mini,
    miniPages,
    miniChallenges,
    miniAchievements,
    miniAdminEmployees,
    miniAdminPages,
    global,
    modals,
    statistics,
    adminMiniChallenges,
    messenger,
    badges
  }
})

export default store
