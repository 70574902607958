<template>
  <div id="app" :class="page" class="video" :style="appStyle">
    <vue-progress-bar></vue-progress-bar>

    <!-- <iframe v-if="companyBgVideo && !isMobileDevice() && ((page[0] === 'home') || (page[0] === 'login') || (page[0] === 'signup') || (page[0] === 'forgot') || (page[0] === 'deleted'))"
            id="bgVideo" class="youtube-video" frameborder="0" height="100%" width="100%"
            :src="`https://youtube.com/embed/${companyBgVideo}?autoplay=1&controls=0&showinfo=0&autohide=1&mute=1&loop=1&playlist=${companyBgVideo}`">
    </iframe>
    <video v-else-if="!isCompanyLoginLoading && !companyBgImage && !isMobileDevice() && ((page[0] === 'home') || (page[0] === 'login') || (page[0] === 'signup') || (page[0] === 'forgot') || (page[0] === 'deleted'))" autoplay="" muted="" loop="" id="bgVideo">
      <source src="https://s3-eu-west-1.amazonaws.com/efectio/video/pingpong.mp4" type="video/mp4">
    </video> -->

    <div v-if="apiError" class="text-center pt-5">
      <lottie
        path="https://efectio.s3-eu-west-1.amazonaws.com/animations/maintenance.json"
        :loop="true"
        :width="200"
      />
      <h6>Questario is under maintenance.</h6>
      <p>Come back later!</p>
    </div>
    <div v-else-if="!_.isEmpty($i18n)" id="wrapper">
      <div v-if="!_.isEmpty($i18n.i18next) && $i18n.i18next.isInitialized" class="container">
        <router-view></router-view>
      </div>

      <footer v-if="!apiError"
              id="main-footer"
              class="main-footer">
        <b-row>
          <b-col sm="12" md="4" lg="4" class="text-center text-md-left">
            <div class="copyright">
              {{ $t('misc.copyrights') }}
            </div>
          </b-col>
          <b-col v-if="1 === 2" sm="12" md="5" lg="5">
            <div class="mx-auto text-center mt-3 mt-md-0">
              <a href="https://itunes.apple.com/us/app/efectio/id1454385161" target="_blank" rel="noopener noreferrer" class="btn btn-download">
                <img src="~@/assets/icon_apple.svg" alt="Apple Logo" class="logo">
                <div class="btn-download-inner">
                  <span class="small">{{ $t('misc.download_ios_app') }}</span>
                  <span>App Store</span>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=lv.efectio.efectioapp" target="_blank" rel="noopener noreferrer" class="btn btn-download ml-0 ml-lg-3 mt-md-3 mt-lg-0">
                <img src="~@/assets/icon_google_play.svg" alt="Google Play Logo" class="logo">
                <div class="btn-download-inner">
                  <span class="small">{{ $t('misc.download_android_app') }}</span>
                  <span>Google Play</span>
                </div>
              </a>
            </div>
          </b-col>
          <b-col sm="12" md="8" lg="8" class="text-center text-md-right mt-3 mt-md-0 cards-logo">
            <a href="#terms" class="d-inline-block mr-3">{{ $t('button.terms') }}</a>
            <a href="#privacy" class="d-inline-block mr-3">{{ $t('button.privacy') }}</a>
            <a href="https://questario.com/" class="d-inline-block" target="_blank">{{ $t('footer.about_questario') }}</a>
          </b-col>
        </b-row>
        <b-row v-if="1 === 2" class="mt-3">
          <b-col cols="12" md="3">
            <a href="#terms" class="d-block">{{ $t('button.terms') }}</a>
            <a href="#privacy" class="d-block">{{ $t('button.privacy') }}</a>
            <a href="https://questario.com/" class="d-block" target="_blank">{{ $t('footer.about_questario') }}</a>
          </b-col>
          <b-col cols="12" md="9">
            <div v-html="$t('footer.company_info')"></div>
          </b-col>
        </b-row>
      </footer>
    </div>

    <div class="modals">
      <terms ref="terms"></terms>
      <privacy ref="privacy"></privacy>
    </div>
  </div>
</template>

<script>
import Debug from '@/util/debug'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { FB_APP_ID, ENV_IS_PRODUCTION } from '@/util/constants'
import { updateFavicon } from '@/util/dom'

const debug = Debug('app')

/* Start of lazy load components config */
import lazyLoadComponent from '@/util/lazy-load-component'
import Preloader from '@/app/global/components/Preloader'
// import SkeletonBox from '@/app/global/components/SkeletonBox'
const lazyLoadOptions = {
  loading: Preloader,
  loadingData: {
    props: {
      width: '100%',
      height: '2rem'
    }
  }
}
/* End of lazy load components config */

export default {
  name: 'app',
  components: {
    Terms: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/modals/components/Terms')}),
    Privacy: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/modals/components/Privacy')})
  },
  data () {
    return {
      toggles: {
        dropdown: false,
        nav: false
      },
      currentHash: this.$route.hash,
      apiError: false,
      pingTimer: null
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      challengeData: 'miniChallenges/singleChallenge',
      isCompanySubdomain: 'auth/isCompanySubdomain',
      companyLogin: 'auth/companyLogin',
      isCompanyLoginLoading: 'auth/isCompanyLoginLoading',
      miniBranding: 'miniPages/miniBranding'
    }),
    page () {
      if (!this.apiError) {
        if (!this.$route.name) {
          return 'page'
        }
        return this.$route.name.split('.')
      }
      return 'maintenance'
    },
    appStyle () {
      let style = {}

      // API error
      if (this.apiError) {
        style.backgroundColor = '#f0f0f0'
        style.backgroundImage = 'none'
        return style
      }

      let challengeId = parseInt(this.$route.params.challengeId)
      if (!isNaN(challengeId)) {
        if (!_.isEmpty(this.challengeData)) {
          if (typeof this.challengeData.background_image !== 'undefined' && !_.isNil(this.challengeData.background_image) && this.challengeData.background_image !== '') {
            // style.backgroundImage = `url(${this.challengeData.background_image})`
            return style
          }
        }
      } else {
        // if (this.isCompanySubdomain) {
        if (this.authenticated) {
          // Mini challenges page
          // if (this.miniBranding.bg_image) {
          //   // style.backgroundImage = `url(${this.miniBranding.bg_image})`
          //   return style
          // }
        } else {
          // Login / Registration pages
          // if (!this.isCompanyLoginLoading) {
          //   if (this.companyLogin.bg_image) {
          //     style.backgroundImage = `url(${this.companyLogin.bg_image})`
          //   }
          //   if (this.companyLogin.bg_color) {
          //     style.backgroundColor = this.companyLogin.bg_color
          //   }
          //   return style
          // }
        }
        // }
      }
      return {}
    },
    currentCompany () {
      if (!_.isEmpty(this.user)) {
        if (!_.isEmpty(this.user.currentCompany)) {
          return this.user.currentCompany
        }
      }
      return {}
    },
    company: function () {
      if (!_.isEmpty(this.user.currentCompany)) {
        let companyData = this.user.currentCompany

        return companyData
      }
      return {}
    },
    companyBgVideo () {
      // if (this.isCompanySubdomain) {
      if (!_.isEmpty(this.companyLogin)) {
        if (!_.isNil(this.companyLogin.login_background_video)) {
          return this.companyLogin.login_background_video
        }
      }
      // }
      return null
    },
    companyBgImage () {
      // if (this.isCompanySubdomain) {
      if (!_.isEmpty(this.companyLogin)) {
        if (!_.isNil(this.companyLogin.login_background_image)) {
          return this.companyLogin.login_background_image
        }
      }
      // }
      return null
    }
  },
  methods: {
    ...mapActions({
      pingAction: 'auth/ping',
      logout: 'auth/logout',
      sendNotificationsToken: 'auth/sendNotificationsToken',
      getCompanyInfo: 'auth/fetchCompanyOnLogin'
    }),
    ...mapMutations({
      setSubdomain: 'global/SET_SUBDOMAIN',
      setLang: 'global/SET_LANGUAGE',
      showUnlockedLevelsMut: 'modals/SET_SHOW_UNLOCKED_LEVELS'
    }),
    updateFavicon: updateFavicon,
    toggle (el) {
      debug.info(el)
      this.toggles[el] = !this.toggles[el]
    },
    signOut () {
      this.logout().then(() => {
        this.$router.replace({name: 'home'})
      })
    },
    openModal (hash) {
      // var openedModals = document.getElementsByClassName('modal-active')
      // while (openedModals.length) {
      //   openedModals[0].className = openedModals[0].className.replace(/\bmodal-active\b/g, '')
      // }

      hash = hash.replace('#', '')

      if (hash === 'privacy') {
        this.$refs.privacy.open()
      }

      if (hash === 'terms') {
        this.$refs.terms.open()
      }

      // if (document.getElementById(hash)) {
      //   if (document.getElementById(hash).classList.contains('modal-active')) {
      //     // Do nothing
      //   } else {
      //     document.getElementById(hash).classList.add('modal-active')
      //   }
      // }
    },
    isMobileDevice () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
    },
    browserName () {
      var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        //return 'IE ' + (tem[1] || '');
        return { name: 'IE ', version: (tem[1] || '') };
      }

      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);
        //if (tem != null) return 'Opera ' + tem[1];
        if (tem != null) { return { name: 'Opera', version: tem[1] }; }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }

      return M[0]
    },
    setShareUrl (url) {
      let urlEl = document.querySelector('head meta[property="og:url"]')
      urlEl.setAttribute('content', url)
    },
    setShareTitle (title) {
      let titleEl = document.querySelector('head meta[property="og:title"]')
      titleEl.setAttribute('content', title)
    },
    setShareDesc (desc) {
      let descEl = document.querySelector('head meta[property="og:description"]')
      descEl.setAttribute('content', desc)
    },
    setShareImg (img) {
      let imageEl = document.querySelector('head meta[property="og:image"]')
      imageEl.setAttribute('content', img)
    },
    setPing () {
      if (!this.pingTimer) {
        let parent = this
        parent.pingAction({ context: parent, payload: {}})
        // Run every 30 seconds
        this.pingTimer = setInterval(function () {
          parent.pingAction({ context: parent, payload: {}})
        }, 30 * 1000)
      }
    },
    unsetPing () {
      clearInterval(this.pingTimer)
      this.pingTimer = null
    }
  },
  mounted () {
    this.openModal(this.currentHash)
    this.$router.afterEach((to, from) => {
      this.openModal(to.hash)
    })
    this.$Progress.finish()

    let slug = ''
    if (ENV_IS_PRODUCTION) {
      slug = location.hostname.split('.').shift()
    } else {
      let url = new URL(location)
      let slugParam = url.searchParams.get('slug')

      if (_.isEmpty(slugParam)) {
        slug = ''
      } else if (slugParam === 'my') {
        slug = ''
      } else {
        slug = slugParam
      }
    }
    this.setSubdomain(slug)

    if (!_.isNil(slug) && !_.isUndefined(slug) && slug !== 'my') {
      this.getCompanyInfo({ payload: slug })
    } else {
      this.$store.state.auth.isCompanyLoginLoading = false
    }
  },
  created () {
    // Ping API every 30 seconds to see its status / to collect platform usage time
    // Stop timer if viewport is out of focus
    // window.addEventListener('blur', (e) => {
    //   this.unsetPing()
    // }, true)
    //
    // window.addEventListener('focus', (e) => {
    //   this.setPing()
    // }, true)
    this.setPing()

    // FB share image
    var url_string = window.location.href
    var url = new URL(url_string)
    let shareurl = url.searchParams.get('shareurl')
    let sharetitle = url.searchParams.get('sharetitle')
    let sharedesc = url.searchParams.get('sharedesc')
    let shareimg = url.searchParams.get('shareimg')

    if (shareurl) {
      this.setShareTitle(shareurl)
    }

    if (sharetitle) {
      this.setShareTitle(sharetitle)
    }

    if (sharedesc) {
      this.setShareDesc(sharedesc)
    }

    if (shareimg) {
      this.setShareImg(shareimg)
    }

    // Add favicon dynamically, based if browser has/hasn't dark mode. Param: notifications icon (true/false)
    this.updateFavicon(false)

    // Start of Progress Bar
    this.$Progress.start()

    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })

    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
    // End of Progress Bar
  },
  watch: {
    apiError: function (value) {
      if (!value) {
        // this.unsetPing()
      }
    }
  }
}
</script>

<style lang="scss">
  @import './assets/styles/app.scss';

  // MUST BE NOT SCOPED
  * {
    box-sizing: border-box;
  }

  html {
    position: relative;
    height: 100%;
  }

  body {
    position: relative;
    font-family: $font-family-sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $body-color;
    margin: 0;
    min-width: 320px;
    min-height: 100%;
  }

  #app {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;

    #wrapper {
      position: relative;
      /*min-height: calc(100% - 128px);*/
      /*height: calc(100% - 128px);*/
      /*padding-bottom: 5.3rem;*/
      border-collapse: collapse;
      height: 100%;

      &>.container {
        padding-top: 80px;
        padding-bottom: 80px;
        min-height: 100%;

        @include media-breakpoint-down(xs) {
          padding: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        min-height: calc(100% - 235px);
        height: calc(100% - 235px);
      }
    }

    &.maintenance {
      background-color: white !important;
    }
  }

  ::-ms-clear {
    display: none;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .navbar {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 50px;

    &.fixed-top {
      z-index: 10002;
    }
  }

  footer.main-footer {
    position: absolute;
    /*bottom: 0;*/
    width: 100%;
    color: $footer-text-color;
    padding: 1.6rem;
    background-color: $footer-bg;
    z-index: 8;

    .row {
      align-items: center;
    }

    a {
      color: $footer-text-color;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }

    nav#footer-navigation {
      // @extend .col-12;
      // @extend .col-sm-6;
      // @extend .col-md-8;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;

          &:not(:last-child) {
            margin-right: 2rem;
          }
        }
      }
    }
    .copyright {
      // @extend .col-12;
      // @extend .col-sm-6;
      // @extend .col-md-4;
      margin: 0 auto;

      img {
        margin-left: 1rem;
        vertical-align: middle;
      }

      @include media-breakpoint-down(xs) {
        margin-top: 1rem;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 80px;
    }
  }

  .fadein-enter-active {
    transition-timing-function: ease-out;
    animation: bounce-in .5s;
  }
  .fadein-leave-active {

  }
  @keyframes bounce-in {
    0% {
      opacity: 1;
      transform: scale(.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>

<style lang="scss" scoped>
  .btn-download {
    display: inline-block;
    margin-bottom: 0;
    font-size: 0.8rem;
    line-height: 1.1rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    padding: 0.3rem;
    color: white;
    border-color: $footer-text-color;
    border-radius: 8px!important;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;

    &:hover {
      border-color: white;
    }

    .logo {
      display: inline-block;
      width: 1.5rem;
      height: auto;
      margin-top: -1.5rem
    }

    .btn-download-inner {
      display: inline-block;
      padding-left: 0.5rem;
      text-align: left;
    }

    span {
      display: block;
      margin-bottom: 0;
      color: #fff!important;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  footer.main-footer {
    .cards-logo {
      img {
        width: 100%;
        max-width: 150px;
        height: auto;
      }
    }
  }
</style>
