import * as types from './mutation-types'
import moment from 'moment'
import store from '@/store'

export default {
  [types.SET_STATS_DATA] (state, data) {
    state.data = data
  },
  [types.SET_ALL_STATS_DATA] (state, data) {
    state.allData = data

    // Add data to steps array
    let stepsObj = {
      steps: {
        data: [],
        labels: []
      },
      calories: {
        data: [],
        labels: []
      },
      distance: {
        data: [],
        labels: []
      }
    }
    for (let i = 0; i < data.length; i++) {
      let activity = data[i].activity
      stepsObj[activity].data.push(data[i].amount)
      stepsObj[activity].labels.push(moment(data[i].date_from).format('DD-MM, HH:mm'))
    }
    state.activitiesChart = stepsObj
  },
  [types.SET_TODAYS_MOOD] (state, data) {
    state.todaysMood = data
  },
  [types.SET_ALL_TRANSACTIONS_DATA] (state, data) {
    state.allTransactions = data
  },
  [types.SET_TRANSACTIONS_BALANCE_DATA] (state, data) {
    state.transactionsBalance = data
  },
  [types.SET_CONNECTED_PROVIDERS_ERROR] (state, data) {
    state.errorConnectedProviders = data
  },
  [types.SET_STATS_ERROR] (state, data) {
    state.errorStats = data
  },
  [types.SET_ALL_STATS_ERROR] (state, data) {
    state.errorAllStats = data
  },
  [types.SET_FITLERED_STATS_ERROR] (state, data) {
    state.errorFilteredStats = data
  },
  [types.SET_HEALTHSCORE_DATA] (state, data) {
    state.healthscore = data
  },
  [types.SET_SCORES_DATA] (state, data) {

    let slug = store.getters['global/subdomain']

    // Fill dummy data for demo account
    if (slug === 'demo') {
      data = {
        wellbeing: 75,
        learning: 65,
        goals: 82,
        social: 97,
        efectio: 90
      }
    }

    state.scores = data
    if (!_.isEmpty(data) && !_.isUndefined(data.wellbeing) && !_.isUndefined(data.learning) && !_.isUndefined(data.social) && !_.isUndefined(data.goals)) {
      let learning = data.learning > 30 ? data.learning : 30 // For larger polar area slice
      let goals = data.goals > 30 ? data.goals : 30 // For larger polar area slice
      let social = data.social > 30 ? data.social : 30 // For larger polar area slice
      let wellbeing = data.wellbeing > 30 ? data.wellbeing : 30 // For larger polar area slice

      state.scoresData = [learning, goals, social, wellbeing]

      // Wellbeing score
      if (data.wellbeing >= 90) {
        state.wellbeingScore = 'excellent'
      } else if (data.wellbeing >= 65) {
        state.wellbeingScore = 'good'
      } else if (data.wellbeing >= 40) {
        state.wellbeingScore = 'average'
      } else if (data.wellbeing > 0) {
        state.wellbeingScore = 'low'
      } else {
        state.wellbeingScore = 'empty'
      }

      // Learning score
      if (data.learning >= 90) {
        state.learningScore = 'excellent'
      } else if (data.learning >= 65) {
        state.learningScore = 'good'
      } else if (data.learning >= 40) {
        state.learningScore = 'average'
      } else if (data.learning > 0) {
        state.learningScore = 'low'
      } else {
        state.learningScore = 'empty'
      }

      // Social score
      if (data.social >= 90) {
        state.socialScore = 'excellent'
      } else if (data.social >= 65) {
        state.socialScore = 'good'
      } else if (data.social >= 40) {
        state.socialScore = 'average'
      } else if (data.social > 0) {
        state.socialScore = 'low'
      } else {
        state.socialScore = 'empty'
      }

      // Goals score
      if (data.goals >= 90) {
        state.goalsScore = 'excellent'
      } else if (data.goals >= 65) {
        state.goalsScore = 'good'
      } else if (data.goals >= 40) {
        state.goalsScore = 'average'
      } else if (data.goals > 0) {
        state.goalsScore = 'low'
      } else {
        state.goalsScore = 'empty'
      }
    }
  }
}
