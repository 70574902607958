import * as api from '../api/index'
import * as types from './mutation-types'
import { SET_LOADING } from '@/app/global/store/mutation-types'
import router from '@/router'

export const getAllEmployeesData = ({ commit }, { context }) => {
  api.getAllEmployeesData().then((response) => {
    commit(types.SET_ALL_EMPLOYEES_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleEmployeeData = ({ commit }, { context, payload }) => {
  commit(types.SET_LOADING, true)
  api.getSingleEmployeeData(payload).then((response) => {
    commit(types.SET_SINGLE_EMPLOYEE_DATA, response.data)
    commit(types.SET_LOADING, false)
  }).catch((error) => {
    context.errors = error.response.data.errors
    commit(types.SET_LOADING, false)
  })
}

export const getSingleEmployeeCharts = ({ commit }, { context, payload }) => {
  api.getSingleEmployeeCharts(payload).then((response) => {
    commit(types.SET_SINGLE_EMPLOYEE_CHARTS, response.data)
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSingleEmployeeResults = ({ commit }, { context, payload }) => {
  api.getSingleEmployeeResults(payload).then((response) => {
    commit(types.SET_SINGLE_EMPLOYEE_RESULTS, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const addEmployee = ({ dispatch, commit }, { context, payload }) => {
  context.isLoading = true
  api.addEmployee(payload).then((response) => {
    context.isLoading = false
    context.success = response.status
    dispatch('getAllEmployeesData', { context: this })
    router.push('/quests/company/employees')
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const addBulkEmployees = ({ dispatch, commit }, { context, payload }) => {
  api.addBulkEmployees(payload).then((response) => {
    context.success = response.status
    dispatch('getAllEmployeesData', { context: this })
    router.push('/quests/company/employees')
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const updateEmployee = ({ dispatch }, { context, payload }) => {
  api.updateEmployee(payload).then((response) => {
    router.push('/quests/company/employees')
    dispatch('getAllEmployeesData', { context: this })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deleteEmployee = ({ dispatch }, { context, payload }) => {
  api.deleteEmployee(payload).then((response) => {
    router.push('/quests/company/employees')
    dispatch('getAllEmployeesData')
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getAllTransactionsData = ({ commit }, { context, payload }) => {
  api.getAllTransactionsData(payload).then((response) => {
    commit(types.SET_SINGLE_EMPLOYEE_ALL_TRANSACTIONS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getTransactionsBalanceData = ({ commit }, { context, payload }) => {
  api.getTransactionsBalanceData(payload).then((response) => {
    commit(types.SET_SINGLE_EMPLOYEE_TRANSACTIONS_BALANCE_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const transferPoints = ({ commit }, payload) => {
  // Empty for now
  // Now should reload the data
}
