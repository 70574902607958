import * as storage from '@/util/storage'
import Debug from '@/util/debug'
import store from '../store'
import { setHttpToken } from '@/util/api'
import * as api from '../app/auth/api'
import i18next from 'i18next'
const debug = Debug('router:beforeEach')

const beforeEach = (to, from, next) => {

  // Change language with GET param when redirecting back to client
  if ('lang' in to.query) {
    let lang = to.query.lang
    // TODO: Automatically check in available languages
    if (lang === 'en' || lang === 'lv') {
      i18next.changeLanguage(lang)
    }
  }

  // First, let's check if token is set as GET parameter (used by native app for authorization on some pages)
  // Real actions with token and redirect params are done in ./index.js file
  let token = null
  let redirect = null
  if ('token' in to.query) {
    token = to.query.token
    debug.info('is token param')

    if ('redirect' in to.query) {
      redirect = to.query.redirect
      debug.info('is redirect param')
    }
    next()
  } else {
    store.dispatch('auth/checkTokenExists').then((token) => {
      let slug = store.getters['global/subdomain']
      api.fetchUser(slug).then((response) => {

        // Check for user's current company and if it has a Basic or Pro plan
        if (!_.isUndefined(response.data.data) && !_.isEmpty(response.data.data)) {
          if (!_.isUndefined(response.data.data.companies) && !_.isEmpty(response.data.data.companies)) {
            if (!_.isUndefined(response.data.data.companies[0].plan)) {
              // Set default after login page, according to company's plan
              if (slug === '' || _.isNil(slug) || slug === 'my') {
                store.commit('global/SET_AFTER_LOGIN_PAGE', 'choose.company')
              } else {
                store.commit('global/SET_AFTER_LOGIN_PAGE', 'basic')
              }
            }
          }
        }

        // Global state for home page after login
        let afterLoginPage = store.getters['global/afterLoginPage']
        debug.info(`AFTER LOGIN PAGE: ${afterLoginPage}`)

        if (to.meta.refreshToken) {
          let slug = {}
          store.dispatch('auth/fetchUser', {context: slug}).then(() => {
            if (!_.isNil(slug)) {
              // store.dispatch('auth/fetchCompany', {payload: slug})
              store.dispatch('fetchCurrentCompany') // Grabs company slug from global getter
            }
            next({ name: afterLoginPage })
            return
          })
        } else if (to.meta.guest) {
          debug.info('to.meta.guest')
          next({ name: afterLoginPage })
        } else if (to.meta.adminAuth) {
          debug.info('Needs admin auth')
          let userRole = store.getters['auth/user']
          userRole = userRole.role
          debug.info(userRole)
          if (userRole !== 'owner' && userRole !== 'admin') {
            next({ name: afterLoginPage })
            return
          } else {
            next()
            return
          }
        } else {
          next()
          return
        }
      }).catch(() => {
        debug.warn('api.fetchUser / is not logged in')
        if (to.meta.needsAuth) {
          storage.set('intended', to.name)
          next({ name: 'login' })
          return
        } else {
          next()
          return
        }
      })
    }).catch(() => {
      debug.warn('auth/checkTokenExists / is not logged in')
      if (to.meta.needsAuth) {
        storage.set('intended', to.name)
        next({ name: 'login' })
        return
      } else {
        next()
        return
      }
    })
  }
}

export default beforeEach
