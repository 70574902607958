export const IDB_NAME = 'questario'
export const IDB_DOMAIN = 'questario.com'

export const IDB_LOCALFORAGE = `${IDB_NAME}:localforage`
export const IDB_LOCALFORAGE_TABLE_GENERAL = 'general'

export const ENV_IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
export const ENV_IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const ENV_IS_TESTING = process.env.NODE_ENV === 'testing'

export const LOCALE_DEFAULT = 'en'
export const LOCALE_DEVELOPMENT = 'en'
export const LOCALE_FALLBACK = LOCALE_DEVELOPMENT
// export const LOCALE_FALLBACK = ENV_IS_DEVELOPMENT ? undefined : LOCALE_DEFAULT

export const DOCUMENT_TITLE = process.env.DOCUMENT_TITLE || 'Questario | Your travel guide'

export const DEBOUNCE_TIMER = 1000
export const DEBOUNCE_TIMER_SLOWER = 2000

// export const AFTER_LOGIN_PAGE = 'mini.all_challenges'
export const AFTER_LOGIN_PAGE = 'mini.home'
export const DIPLOMA_AFTER_DAYS = 1

export const DEFAULT_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Riga'

export const FILTER_TYPES = {
  // MULTISELECT: 'multiselect',
  // DATE_FROM_TO: 'datefromto',
  SELECT: 'select',
  BUTTON_GROUP: 'buttonGroup',
  TEXT_INPUT: 'textinput'
}

export const CHART_COLORS = [
  '#00c091',
  '#ff35b3',
  '#7d54e4',
  '#5494e4',
  '#54d8e4',
  '#f2c14c',
  '#f2734c'
]

// For production - 538107523206809
// For development - 1950721441656753
// export const FB_APP_ID = 1950721441656753 // Development
// export const FB_APP_SECRET = '3588de220fc3e08c335ea2f27f41dbb5' // Development

export const FB_APP_ID = 302811871398700 // Production
export const FB_APP_SECRET = 'e2175390fef59ac1388c2e7a120f3dce' // Production

// export const GOOGLE_CLIENT_ID = '3111985437-g72n19v6rgdjbf592deuv9iegr5tqshh.apps.googleusercontent.com' // Development
// export const GOOGLE_CLIENT_SECRET = 'ahrkVelp-cmnGZ9UddRujY0p' // Development

export const GOOGLE_CLIENT_ID = '1082971002628-mj72kjho5mpr70317gssg5lesg8c1h0e.apps.googleusercontent.com' // Production
export const GOOGLE_CLIENT_SECRET = 'O7NZXUXIE0ZQM3hBxjU0x_1f' // Production

export const FB_SRC = 'https://connect.facebook.net/en_US/all.js'

export const IframeAPIReady = new Promise((resolve, reject) => {
  window.onYouTubeIframeAPIReady = () => resolve()
})

export const STRIPE_KEY = process.env.NODE_ENV === 'production' ? 'pk_live_vxoXDUOGQoZImVQNTihFnZBK' : 'pk_test_R4aRmVzIAuCjTimzUdjQR9Zu' // Production -> Development

export const MIXPANEL_TOKEN = '760bbdd7d06c169de464c4a0d017bdd5'
