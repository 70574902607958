export const badges = (state) => {
  return state.badges
}

export const userBadges = (state) => {
  return state.userBadges
}

export const unlockedBadges = (state) => {
  return state.unlockedBadges
}
