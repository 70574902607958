import axios from 'axios'

export const getAllChallengesData = () => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/challenges`)
}

export const getSingleChallengeData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/challenges/${payload.challenge_id}`)
}

export const getPrizesCategoriesData = () => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/prizes/categories`)
}

export const getPrizesPartnersData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/prizes/partners/${payload}`)
}

export const getPrizesItemsData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/prizes/prizes/${payload}`)
}

export const updateChallenge = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/challenges`, payload)
}

export const addPrize = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/prizes/prizes`, payload)
}

export const updatePrize = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/prizes/prizes`, payload)
}

export const addPartner = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/prizes/partners`, payload)
}

export const updatePartner = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/prizes/partners`, payload)
}

export const changePrizesOrder = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/prizes/order`, payload)
}

export const createNewChallenge = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/challenges`, payload)
}

export const deleteChallenge = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/mc/company/challenges/${payload}`)
}

export const startLotteryStep1 = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/prizes/lottery/1`, payload)
}

export const startLotteryStep2 = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/prizes/lottery/2`, payload)
}

export const startLotteryStep3 = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/prizes/lottery/3`, payload)
}

export const cancelLottery = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/prizes/lottery/cancel`, payload)
}

export const getAllLotteriesData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/prizes/lottery/all/${payload}`)
}

export const getSingleLotteryData = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/prizes/lottery/${payload}`)
}

export const deleteAchievement = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/achievements/delete`, payload)
}

export const getSingleChallengeDays = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/challenges/${payload.challenge_id}/days`)
}

export const getSingleChallengeDay = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/challenges/${payload.challenge_id}/days/${payload.day_id}`)
  // return axios.get(`https://private-4b8122-efectio2.apiary-mock.com/v1/company/challenges/${payload.challenge_id}/days/${payload.day_id}`)
}

export const getSingleChallengeTasks = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/mc/company/challenges/${payload.challenge_id}/days/${payload.day_id}/tasks`)
}

export const createNewChallengeTask = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/mc/company/challenges/tasks`, payload)
}

export const updateChallengeDaysOrder = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/challenges/${payload.challenge_id}/days/order`, payload)
}

export const updateChallengeDay = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/challenges/days`, payload)
}

export const updateChallengeTask = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/challenges/tasks`, payload)
}

export const deleteChallengeTask = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/mc/company/challenges/tasks/${payload.id}`)
}

export const updateChallengeTasksOrder = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/mc/company/challenges/${payload.challenge_id}/days/${payload.day_id}/tasks/order`, payload)
}
