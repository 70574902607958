import * as types from './mutation-types'

export default {
  [types.SET_ALL_ACHIEVEMENTS_DATA] (state, data) {
    state.allAchievements = data
  },
  [types.SET_SINGLE_ACHIEVEMENT_DATA] (state, data) {
    state.singleAchievement = data
  },
  [types.EDIT_ACHIEVEMENT_DATA] (state, data) {
    let achievements = state.allAchievements.data.achievements
    let index = _.findIndex(achievements, function (item) { return item.id === data.id })
    state.allAchievements.data.achievements[index].likes_count = data.likes_count
    state.allAchievements.data.achievements[index].user_liked = data.user_liked
    state.allAchievements.data.achievements[index].points = data.points - state.allAchievements.data.achievements[index].extra_points
  },
  [types.SET_TOTAL_ACHIEVEMENTS] (state, data) {
    state.totalAchievements = data
  },
  [types.SET_USER_SCORE] (state, data) {
    state.selectedScore = data
  },
  [types.SET_LOADING] (state, trueOrFalse) {
    state.loading = trueOrFalse
  },
  [types.SET_COMPANY_TEAMS_DATA] (state, data) {
    state.companyTeams = data
  }
}
