export const isLoading = state => state.loading

export const allEmployees = (state) => {
  const data = state.allEmployees.data
  return data
}

export const employeesCount = (state) => {
  if (!_.isEmpty(state.allEmployees.meta)) {
    if (!_.isEmpty(state.allEmployees.meta.pagination)) {
      return state.allEmployees.meta.pagination.total
    }
  }
}

export const singleEmployee = (state) => {
  const data = state.singleEmployee.data
  return data
}

export const singleEmployeeCharts = (state) => {
  const data = state.singleEmployeeCharts.data
  return data
}

export const singleEmployeeResults = (state) => {
  const data = state.singleEmployeeResults.data
  return data
}

export const singleEmployeeAllTransactions = (state) => {
  return state.singleEmployeeAllTransactions
}

export const singleEmployeeTransactionsBalance = (state) => {
  return state.singleEmployeeTransactionsBalance
}
