<template>
  <div class="row justify-content-center">
    <div class="loading"></div>
  </div>
</template>

<script>
export default {
  name: 'loading'
}
</script>

<style lang="scss" scoped>
  $base-line-height: 48px;
  $white: rgb(255,255,255);
  $off-white: rgba($white, 0.2);
  $spin-duration: 1s;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading {
      border-radius: 50%;
      width: $base-line-height;
      height: $base-line-height;
      border: .25rem solid rgba($brand-primary, 0.2);
      border-top-color: $brand-primary;
      animation: spin $spin-duration infinite linear;
      &--double {
        border-style: double;
        border-width: .5rem;
      }
    }
</style>
