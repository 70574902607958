import * as types from './mutation-types'

export default {
  [types.SET_ALL_CHALLENGES_DATA] (state, data) {
    state.allChallenges = data
  },
  [types.SET_SINGLE_CHALLENGE_DATA] (state, data) {
    state.singleChallenge = data
  },
  [types.SET_PRIZES_CATEGORIES_DATA] (state, data) {
    state.prizesCategories = data
  },
  [types.SET_PRIZES_PARTNERS_DATA] (state, data) {
    state.prizesPartners = data
  },
  [types.SET_PRIZES_ITEMS_DATA] (state, data) {
    state.prizesItems = data
  },
  [types.SET_LOTTERY_WINNERS_DATA] (state, data) {
    state.lotteryWinners = data
  },
  [types.SET_ALL_LOTTERIES_DATA] (state, data) {
    state.allLotteries = data
  },
  [types.SET_SINGLE_LOTTERY_DATA] (state, data) {
    state.singleLottery = data
  },
  [types.SET_LOADING] (state, trueOrFalse) {
    state.loading = trueOrFalse
  },
  [types.SET_SINGLE_CHALLENGE_DAYS] (state, data) {
    state.singleChallengeDays = data
  },
  [types.SET_SINGLE_CHALLENGE_DAY] (state, data) {
    state.singleChallengeDay = data
  },
  [types.SET_SINGLE_CHALLENGE_TASKS] (state, data) {
    state.singleChallengeTasks = data
  },
  [types.SET_SINGLE_CHALLENGE_TASK] (state, data) {
    state.singleChallengeTask = data
  },
  [types.SET_DAY_LOADING] (state, data) {
    state.isDayLoading = data
  },
  [types.SET_TASKS_LOADING] (state, data) {
    state.isTasksLoading = data
  }
}
