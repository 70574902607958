import Avatar from './Avatar'
import Loading from './Loading'
import Modal from './Modal'
import PhoneInput from './PhoneInput'
import Preloader from './Preloader'
import ResponseErrors from './ResponseErrors'
import ResponseSuccess from './ResponseSuccess'
import Spacer from './Spacer'
import SwitchControl from './SwitchControl'
import CharsCounter from './CharsCounter'
import Lottie from './Lottie'

export default {
  Avatar,
  Loading,
  Modal,
  PhoneInput,
  Preloader,
  ResponseErrors,
  ResponseSuccess,
  Spacer,
  SwitchControl,
  CharsCounter,
  Lottie
}
