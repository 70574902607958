<template id="modal">
  <div>
    <transition name="modal">
      <div class="modal-mask" v-if="isActive">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <a v-if="1 === 2" @click="goBack()" href="#" class="back">←<span>{{ $t('button.back') }}</span></a>
              <div class="head" v-if="hasHeaderSlot">
                <h3 class="card-title">
                  <slot name="header">{{ $t('dashboard.home.greeting') }}, {Customer}!</slot>
                </h3>
                <slot name="spacer"><spacer></spacer></slot>
              </div>
              <a @click.prevent="close()" href="#" class="close"><i class="material-icons">close</i></a>
            </div>

            <div class="modal-intro" v-if="hasIntroSlot">
              <slot name="intro">
                <p>default intro text</p>
              </slot>
            </div>

            <div class="modal-body" v-if="hasBodySlot">
              <slot name="body">
                default body
              </slot>
            </div>

            <div class="modal-footer" v-if="hasFooterSlot">
              <slot name="footer">
                default footer
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  template: '#modal',
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header']
    },
    hasIntroSlot () {
      return !!this.$slots['intro']
    },
    hasBodySlot () {
      return !!this.$slots['body']
    },
    hasFooterSlot () {
      return !!this.$slots['footer']
    }
  },
  methods: {
    open () {
      this.isActive = true
    },
    close () {
      this.isActive = false
      this.$emit('hasClosed')
      // var elements = document.getElementsByClassName('modal-active')
      // var currentModal = elements[0]
      // currentModal.classList.remove('modal-active')
    },
    goBack () {
      this.close()
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-mask {
    display: block;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    overflow-y: scroll;
  }

  .modal-active {
    .modal-mask{
      display: block;
    }
  }

  .modal-wrapper {
    display: block;
    margin-top: 4rem;

    @include media-breakpoint-down(xs) {
      margin-top: 0px !important;
      height: 100%;
    }
  }

  .modal-container {
    width: 100%;
    max-width: 600px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-down(xs) {
      height: 100%;
    }
  }

  .modal-header {
    position: relative;
    padding-bottom: 0;
    border-bottom: 0;
    min-height: 2.5rem;
    padding-top: 2rem;

    a.back {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 1rem;
      text-transform: uppercase;
      text-decoration: none;
      span {
        font-size: 0.8rem;
        padding-left: 0.5rem;
        text-decoration: underline;
      }
    }
    a.close {
      position: absolute;
      right: 0;
      top: 0;
    }
    .head {
      margin: 0 auto;
      text-align: center;

      .spacer {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }

  .hide-back .modal-header a.back {
      display: none;
  }

  .modal-intro {
    margin-top: 1.2rem;
    text-align: center;
  }

  .modal-body {
    margin: 20px 0;
    text-align: center;
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
