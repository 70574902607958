export const defaultData = {
  meta: {
    pagination: {
      filtered: 0,
      total: 0,
      per_page: 10,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  data: []
}

export default {
  endpoint: null,
  error: null,
  filters: {},
  loading: false,
  response: defaultData,
  subdomain: '',
  content: 'company', // 'public' or 'company'
  afterLoginPage: 'mini.challenges'
}
