const Home = () => import(/* webpackChunkName: "MiniAdminPages" */ '../components/Home')
const Challenges = () => import(/* webpackChunkName: "MiniAdminPages" */ '../components/Challenges')
const Employees = () => import(/* webpackChunkName: "MiniAdminPages" */ '../components/Employees')
const Departments = () => import(/* webpackChunkName: "MiniAdminPages" */ '../components/Teams')
const Settings = () => import(/* webpackChunkName: "MiniAdminPages" */ '../components/Settings')
const Branding = () => import(/* webpackChunkName: "MiniAdminPages" */ '../components/Branding')
const Badges = () => import(/* webpackChunkName: "MiniAdminPages" */ '../components/Badges')

import AdminMiniChallenges from '@/app/mini-admin-challenges/routes'
import AdminEmployees from '@/app/mini-admin-employees/routes'

export default [
  {
    path: '',
    name: 'mini.company.home',
    component: Home,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Home'
    }
  },
  {
    path: 'challenges',
    name: 'mini.company.challenges',
    component: Challenges,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Challenges'
    },
    children: AdminMiniChallenges
  },
  {
    path: 'employees',
    name: 'mini.company.employees',
    component: Employees,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Employees'
    },
    children: AdminEmployees
  },
  {
    path: 'teams',
    name: 'mini.company.teams',
    component: Departments,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Teams'
    },
    children: AdminEmployees
  },
  {
    path: 'settings',
    name: 'mini.company.settings',
    component: Settings,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Settings'
    }
  },
  {
    path: 'branding',
    name: 'mini.company.branding',
    component: Branding,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Branding'
    }
  },
  {
    path: 'badges',
    name: 'mini.company.badges',
    component: Badges,
    meta: {
      dashboard: 'company',
      adminAuth: true,
      needsAuth: true,
      breadcrumb: 'Badges'
    }
  }
]
