const SignupStep1 = () => import(/* webpackChunkName: "AuthSteps" */ '../components/step1-signup')
const SignupStep1Email = () => import(/* webpackChunkName: "AuthSteps" */ '../components/step1-signup-email')
const SignupStep2 = () => import(/* webpackChunkName: "AuthSteps" */ '../components/step2-signup')
const SignupStep3 = () => import(/* webpackChunkName: "AuthSteps" */ '../components/step3-signup')

export default [
  {
    path: 'step/1',
    name: 'signup.step1',
    component: SignupStep1,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/1/confirm',
    name: 'signup.step1.email',
    component: SignupStep1Email,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/2',
    name: 'signup.step2',
    component: SignupStep2,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/3',
    name: 'signup.step3',
    component: SignupStep3,
    meta: { guest: false, needsAuth: false }
  }
]
