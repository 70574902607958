<template>
    <div>
      <div v-if="Object.keys(errorsComp).length > 0">
        <b-alert variant="danger" v-for="error in errorsComp" :key="error.code" class="mb-0" show>
          <spacer color="pink"></spacer>
          <div v-if="!_.isUndefined(error.stripe) && !_.isArray(error.stripe) && !_.isEmpty(error.stripe)">
            {{ $t(`errors.stripe.${error.stripe.code}`) }}
            <div v-if="!_.isUndefined(error.stripe.decline_code)">
              <ul><li>{{ $t(`errors.stripe.decline_codes.${error.stripe.decline_code}`) }}</li></ul>
            </div>
          </div>
          <div v-else-if="!_.isUndefined(error.stripe) && _.isArray(error.stripe) && error.stripe.length > 0">
            <div v-for="stripeError in error.stripe" :key="stripeError.code">
              {{ $t(`errors.stripe.${stripeError.code}`) }}
              <div v-if="!_.isUndefined(stripeError.decline_code)">
                <ul><li>{{ $t(`errors.stripe.decline_codes.${stripeError.decline_code}`) }}</li></ul>
              </div>
            </div>
          </div>
          <div v-else-if="!_.isUndefined(error.code)">
            {{ $t(`errors.${error.code}`) }}
          </div>
          <div v-else>
            {{ error }}
          </div>
        </b-alert>
      </div>
    </div>
</template>

<script>
export default {
  name: 'response-errors',
  props: {
    errors: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data () {
    return {}
  },
  computed: {
    computedClass () {
      let classes = []

      classes.push(this.color)
      classes.push('w-' + this.size)

      return classes
    },
    errorsComp () {
      if (!_.isUndefined(this.errors)) {
        return this.errors
      }
      return []
    }
  },
  watch: {
    errors: function (val, oldVal) {
      // this.displayedErrors = val
    }
  }
}
</script>

<style lang="scss" scoped>
  .pink {
    background-color: $brand-danger;
  }

  .yellow {
    background-color: $brand-warning;
  }

  .green {
    background-color: $form-valid-color;
  }

  .blue {
    background-color: $brand-primary;
  }

  .spacer {
    // @extend .my-4;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
