export const userConversations = (state) => {
  return state.userConversations
}

export const userMessages = (state) => {
  return state.userMessages
}

export const selectedProfessional = (state) => {
  return state.selectedProfessional
}

export const selectedConversation = (state) => {
  return state.selectedConversation
}

export const isLoading = (state) => {
  return state.isLoading
}

export const inputDisabled = (state) => {
  return state.inputDisabled
}
