import axios from 'axios'

export const getAllProviders = () => {
  return axios.get(`${process.env.API_URL}/v1/integrations`)
}

export const getConnectedProviders = () => {
  return axios.get(`${process.env.API_URL}/v1/integrations/connected`)
}

export const postProvider = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/integrations/connect`, payload)
}

export const setDefaultProvider = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/integrations/update/default`, payload)
}

export const deleteProvider = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/integrations/update/delete`, payload)
}

export const suggestProvider = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/integrations/suggest`, payload)
}
