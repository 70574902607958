// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

import { i18n } from './app/boot'
import i18next from 'i18next'

// set up our global plugins and utilities
require('./app/boot')

Vue.config.productionTip = false

/* eslint-disable no-new */
i18next.init().on('initialized', () => {

})

new Vue({
  components: { App },
  el: '#app',
  i18n,
  router,
  store,
  template: '<App/>'
})
