<template>
    <div class="switch-control" :style="cssProps">
      <span
        :class="labelColorClass"
        v-if="label"
        v-text="label"
      />
      <input
        :checked="value"
        :disabled="disabled"
        :id="id"
        @change.stop="trigger"
        class="ig-control__switch"
        type="checkbox"
      />
      <label
        :for="id"
        class="ig-control__lbl"
      />
    </div>
</template>

<script>
export default {
  name: 'switch-control',
  props: {
    value: {
      default: false
    },
    disabled: {
      default: false
    },
    label: {
      default: ''
    },
    color: {
      default: 'default'
    },
    labelColor: {
      default: 'default'
    },
    switchColor: {
      default: ''
    }
  },
  data () {
    return {
      id: null,
      toggled: !!this.value,
      switchBgColor: this.switchColor
    }
  },
  computed: {
    labelColorClass () {
      return ['ig-control__label', this.labelColor]
    },
    cssProps () {
      if (_.isEmpty(this.switchBgColor)) {
        this.switchBgColor = '#2ab27b'
      }
      return {
        '--switch-color': this.switchBgColor
      }
    }
  },
  methods: {
    trigger (e) {
      this.$emit('input', e.target.checked)
    }
  },
  mounted () {
    this.id = `ig-control-${this._uid}`
  }
}
</script>

<style lang="scss" scoped>
  .red { color: $brand-danger; }
  .green { color: $brand-success; }

  .ig-control {
    margin: 20px 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__label {
      padding: 0;
      background-color: transparent;
    }

    &__switch:not(:checked),
    &__switch:checked {
      opacity: 0;
      position: absolute;
      margin: 0;
      z-index: -1;
      width: 0;
      height: 0;
      overflow: hidden;
      left: 0;
      pointer-events: none;
    }

    &__switch {
      ~ label {
        position: relative;
        display: block;
        width: 35px;
        height: 14px;
        background-color: #757575;
        color: transparent;
        border-radius: 100px;
        cursor: pointer;
        transition: all .2s ease-in;
      }

      ~ label:after {
        content: '';
        position: absolute;
        top: -3px;
        left: -1px;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(72, 72, 72, 0.50);
        transition: all .2s ease-in;
      }

      &:checked ~ label {
        background: rgba($brand-success, .5);
        &:after {
          transform: translateX(16px);
          background: var(--switch-color);
        }
      }

      &:disabled ~ label {
        background: #F0F0F0;
        pointer-events: none;
        &:after {
          background: #D1D1D1;
          box-shadow: 0 1px 2px 0 rgba(72, 72, 72, 0.50);
        }
      }
    }
  }
</style>
