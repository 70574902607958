export const isLoading = state => state.loading

export const allAchievements = (state) => {
  const data = state.allAchievements.data
  return data
}

export const singleAchievement = (state) => {
  return state.singleAchievement.data
}

export const totalAchievements = (state) => {
  return state.totalAchievements
}

export const selectedScore = (state) => {
  return state.selectedScore
}

export const companyTeams = (state) => {
  return state.companyTeams.data
}
