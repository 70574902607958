import * as types from './mutation-types'

export default {
  [types.SET_DATA] (state, { response, error }) {
    state.response = response
    state.error = error
  },
  [types.SET_ENDPOINT] (state, endpoint) {
    state.endpoint = endpoint
  },
  [types.SET_FILTERS_DATA] (state, { data, error }) {
    state.filters = data
    state.error = error
  },
  [types.SET_LOADING] (state, loading) {
    state.loading = loading
  },
  [types.SET_SUBDOMAIN] (state, data) {
    state.subdomain = data
  },
  [types.SET_CONTENT] (state, data) {
    state.content = data
  },
  [types.SET_AFTER_LOGIN_PAGE] (state, data) {
    if (data === 'basic') {
      state.afterLoginPage = 'mini.challenges'
    } else if (data === 'public') {
      state.afterLoginPage = 'mini.challenges'
    } else if (data === 'choose.company') {
      state.afterLoginPage = 'choose.company'
    } else if (data === 'mini.company.home') {
      state.afterLoginPage = 'mini.company.home'
    } else {
      state.afterLoginPage = 'mini.challenges'
    }
  }
}
