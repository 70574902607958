export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_KEY = 'SET_KEY'
export const SET_USER = 'SET_USER'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_BASIC_USER_DATA = 'SET_BASIC_USER_DATA'
export const SET_USER_FORM_DATA = 'SET_USER_FORM_DATA'
export const SET_USER_DEFAULT_CARD = 'SET_USER_DEFAULT_CARD'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_USER_COMPANY_POINTS = 'SET_USER_COMPANY_POINTS'
export const SET_USER_DEFAULT_COMPANY = 'SET_USER_DEFAULT_COMPANY'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_SHOW_UNLOCKED_FEATURE = 'SET_SHOW_UNLOCKED_FEATURE'
export const SET_JUST_UNLOCKED_FEATURE = 'SET_JUST_UNLOCKED_FEATURE'
export const SET_COMPANY_LOGIN = 'SET_COMPANY_LOGIN'
export const SET_IS_COMPANY_LOGIN_LOADING = 'SET_IS_COMPANY_LOGIN_LOADING'
export const SET_IS_COMPANY_SUBDOMAIN = 'SET_IS_COMPANY_SUBDOMAIN'
export const SET_COMPANY_TEAMS_DATA = 'SET_COMPANY_TEAMS_DATA'
export const SET_COMPANY_TEAM_EMPLOYEES_DATA = 'SET_COMPANY_TEAM_EMPLOYEES_DATA'
export const SET_COMPANY_TEAM_RESULTS_DATA = 'SET_COMPANY_TEAM_RESULTS_DATA'
export const SET_USER_NOTIFICATIONS_DATA = 'SET_USER_NOTIFICATIONS_DATA'
export const SET_USER_NOTIFICATIONS_AS_READ = 'SET_USER_NOTIFICATIONS_AS_READ'
export const SET_NOTIFICATIONS_SETTINGS_DATA = 'SET_NOTIFICATIONS_SETTINGS_DATA'
export const SET_DAILY_TASKS = 'SET_DAILY_TASKS'
